import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
  checkVideoExists,
  createCourse,
  getAllCategories,
  getTopicList,
} from "../../api/request";
import { useLocation } from "react-router-dom";
import { Loader, AppButton, ViewCourseModal } from "../../common";
import { showErrorMessage, showSuccessMessage } from "../../common/Toast";
import { Footer } from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { courseDetails } from "../../api/request";
import DataLossAlert from "../../common/DataLossAlert";
import ErrorModal from "../../common/ErrorModal";

const EditCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const mainContainerRef = useRef(null);

  const [courseData, setCourseData] = useState({});
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [thumbnailType, setThumbnailType] = useState("");
  const [video_type, setVideo_type] = useState("youtube");
  const [viewModal, setViewModal] = useState(false);
  const [categoryId, setCategoryId] = useState({
    id: "",
    name: "",
    isActive: false,
  });
  const [topicId, setTopicId] = useState({
    id: "",
    name: "",
    isActive: false,
  });
  const [topicModal, setTopicModal] = useState(false);
  const [youTubeId, setYouTubeId] = useState("");
  const [errorModal, setErrorModal] = useState({
    message: "",
    isShow: false,
  });

  const {
    handleSubmit,
    register,
    setValue,
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const watchedInputValue = watch();

  useEffect(() => {
    getCourseCategories();
    getCourseDetails();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainContainerRef]);

  const handleClickOutside = (event) => {
    if (
      mainContainerRef.current &&
      !mainContainerRef.current.contains(event.target)
    ) {
      setTopicId((prevState) => ({
        ...prevState,
        isActive: false,
      }));
      setCategoryId((prevState) => ({
        ...prevState,
        isActive: false,
      }));
    }
  };

  const getCourseDetails = async () => {
    if (id !== null) {
      setIsLoad(true);
      const result = await courseDetails(id);
      if (result?.status) {
        setCourseData(result?.data);
        const {
          title,
          intro,
          producer,
          thumbnail,
          video_url,
          topic,
          category,
          category_id,
          topic_id,
        } = result?.data;
        setValue("title", title);
        setValue("intro", intro);
        setValue("producer", producer);
        setValue("video_url", video_url);
        //setValue("category", category);
        const topicList = await fetchTopics(result?.data?.category_id);
        if (topicList?.length > 0) {
          setTopicId((prevState) => ({
            ...prevState,
            name: topic,
            id: topic_id?.toString(),
          }));
        }
        // setCategoryId(category_id?.toString());
        // setTopicId(topic_id);
        setCategoryId((prevState) => ({
          ...prevState,
          name: category,
          id: category_id?.toString(),
        }));
        // setTopicId((prevState) => ({
        //   ...prevState,
        //   id: topic_id,
        // }));
        setThumbnail(thumbnail);
        setIsLoad(false);
      } else {
        setIsLoad(false);
      }
    }
  };

  const getCourseCategories = async () => {
    const result = await getAllCategories();
    if (result?.status) {
      setCategories(result?.data);
    }
  };

  const onFileChange = (event, flag) => {
    if (flag === "thumbnail") {
      let file = event.target.files[0];
      setThumbnail(file);
      setThumbnailType("file");
    }
  };

  const handleTopCategory = (val) => {
    // const index = val.target.selectedIndex;
    // const el = val.target.childNodes[index];
    // const option = el.getAttribute("id");
    // setTopicId("");
    // setValue("topic", "");
    // fetchTopics(option, "id");
    // setCategoryId(option);
    // setValue("category", val.target.value);
    setTopicId((prevState) => ({
      ...prevState,
      id: "",
      name: "",
    }));
    fetchTopics(val?.id, "id");
    setCategoryId((prevState) => ({
      ...prevState,
      id: val?.id,
      name: val?.name,
      isActive: !categoryId.isActive,
    }));
  };

  const handleTopics = (val) => {
    // console.log(val.target.value, categoryId);
    if (val === "newTopic") {
      setTopicModal(categoryId?.name?.length > 0 ? true : false);
      //setValue("topic", "newTopic");
    } else {
      setTopicId((prevState) => ({
        ...prevState,
        id: val?.id,
        name: val?.name,
        isActive: !topicId.isActive,
      }));
    }
  };

  const fetchTopics = async (id) => {
    const result = await getTopicList(id);
    if (result?.status) {
      setTopics(result?.data);
      return result?.data;
    }
  };

  const submitForm = async (data) => {
    let payload = {
      ...data,
      category: categoryId?.name,
      course_id: courseData?.id,
      topic: topicId?.name,
      thumbnail,
      video_type,
    };
    // console.log(payload);
    const result = await createCourse(payload);
    if (result?.status) {
      console.log("res", result);
      showSuccessMessage("Course updated successfully!");
      navigate("/myCourse");
    } else {
      setErrorModal({
        ...errorModal,
        message: result?.response?.data?.message,
        isShow: true,
      });
    }
  };

  return (
    <>
      <Header />
      <DataLossAlert />
      {/* Error modal */}
      {errorModal?.isShow && (
        <ErrorModal
          errorMessage={errorModal?.message}
          onClick={() => {
            setErrorModal({ ...errorModal, isShow: false });
          }}
        />
      )}
      {/* View course Modal */}
      {viewModal && (
        <ViewCourseModal
          intro={watchedInputValue?.intro}
          title={watchedInputValue?.title}
          category={watchedInputValue?.category}
          topic={watchedInputValue?.topic}
          category_id={categoryId}
          topic_id={topicId}
          producer={watchedInputValue?.producer}
          thumbnail={thumbnail}
          thumbnailType={thumbnailType}
          video_type={video_type}
          video_url={watchedInputValue?.video_url}
          handleHide={() => setViewModal(false)}
        />
      )}
      {topicModal && (
        <div
          className="raund-modal raund_m1"
          id="raund-user"
          style={{ zIndex: 999 }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body modal-inner-box  ">
                <div className="model-content">
                  <h3>Add new topic name</h3>

                  <div className="ineer-popup-close-btn submit-popup">
                    <div className="d-flex ps-5 pe-5">
                      <p className="text-light">
                        <b className="text-light">Category: </b>
                        {categoryId?.name}
                      </p>
                    </div>
                    <div className="d-flex ps-5 pe-5 mb-5">
                      <b className="text-light">Add new Topic:</b>
                      <div className="ms-3 w-60">
                        <input
                          type="text"
                          onChange={(e) => {
                            setTopicId(e.target.value);
                            // const newArr = [...topics];
                            // newArr.push({ id: "ID", name: e.target.value });
                            // setTopics(newArr);
                            // setValue("topic", e.target.value);
                          }}
                          placeholder="Add new topic"
                        />
                      </div>
                    </div>
                    <AppButton
                      data-bs-dismiss="modal"
                      className="green-btn"
                      onClick={() => {
                        setTopicModal(false);
                      }}
                    >
                      Submit
                    </AppButton>
                    <AppButton
                      data-bs-dismiss="modal"
                      className="green-btn delete-btn"
                      onClick={() => {
                        setTopicModal(false);
                      }}
                    >
                      Cancel
                    </AppButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoad ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <h4 className="adddetail_heading text-center">
                EDIT YOUR COURSE DETAILS
              </h4>
              <div className="col-md-12">
                <form
                  className="adddetail_form"
                  id="form"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="row">
                    <div
                      className="col-md-4 text-center"
                      ref={mainContainerRef}
                    >
                      <h2>1</h2>
                      <div className="inputHeader">
                        <p>Add Course Name:*</p>
                        <div className="w-75 m-auto">
                          <input
                            type="text"
                            name="title"
                            className="form-control w-100"
                            placeholder="Add course name"
                            {...register("title", {
                              required: {
                                value: true,
                                message: "Course Name is required",
                              },
                            })}
                          />
                          <span style={{ color: "red", fontSize: 14 }}>
                            {errors?.title?.message}
                          </span>
                        </div>
                      </div>
                      <div className="inputHeader">
                        <p>Add Course Intro:*</p>
                        <div className="w-75 m-auto">
                          <input
                            type="text"
                            name="title"
                            maxLength={150}
                            className="form-control w-100"
                            placeholder="Add course intro (max 150 words)"
                            {...register("intro", {
                              required: {
                                value: true,
                                message: "Course Intro is required",
                              },
                            })}
                          />
                          <span style={{ color: "red", fontSize: 14 }}>
                            {errors?.intro?.message}
                          </span>
                        </div>
                      </div>
                      {/* <div className="inputHeader">
                        <p>Select Category:*</p>
                        <Controller
                          name="category"
                          control={control}
                          className="form-control w-100"
                          rules={{ required: true }}
                          render={({ field: { onChange, ...field } }) => (
                            <select
                              {...field}
                              required={true}
                              onChange={handleTopCategory}
                            >
                              <option
                                value=""
                                disabled
                                style={{ fontFamily: "Poppins" }}
                              >
                                Select category
                              </option>
                              {categories &&
                                categories?.map((cat) => (
                                  <option
                                    // className="adddetail_heading_cat"
                                    style={{ fontFamily: "Poppins" }}
                                    id={cat.id}
                                    key={cat.id}
                                    value={cat.name}
                                  >
                                    {cat.name}
                                  </option>
                                ))}
                            </select>
                          )}
                        />
                        <span style={{ color: "red", fontSize: 14 }}>
                          {errors?.category?.message}
                        </span>
                      </div>
                      <div className="inputHeader">
                        <p>Select Topic:*</p>
                        <Controller
                          name="topic"
                          control={control}
                          className="form-control w-100"
                          render={({ field: { onChange, ...field } }) => (
                            <select
                              {...field}
                              required={true}
                              onChange={handleTopics}
                            >
                              <option value="" disabled>
                                Select Topic
                              </option>

                              {topics &&
                                topics.map((topic) => (
                                  <option
                                    style={{ fontFamily: "Poppins" }}
                                    id={topic.id}
                                    key={topic.id}
                                    value={topic.name}
                                  >
                                    {topic.name}
                                  </option>
                                ))}
                              <option
                                value="newTopic"
                                style={{ fontFamily: "Poppins" }}
                              >
                                Select new topic
                              </option>
                            </select>
                          )}
                        />
                        <span style={{ color: "red", fontSize: 14 }}>
                          {errors?.topic?.message}
                        </span>
                      </div> */}
                      <div
                        style={{
                          position: "relative",
                          zIndex: "9",
                        }}
                        className="inputHeader w-75 me-auto ms-auto"
                      >
                        <p>Select Category:*</p>
                        <div className="basic-cate">
                          <div className="dropdown">
                            <div
                              className="cate-first"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCategoryId((prevState) => ({
                                  ...prevState,
                                  isActive: !categoryId.isActive,
                                }));
                                setTopicId((prevState) => ({
                                  ...prevState,
                                  isActive: false,
                                }));
                              }}
                            >
                              {categoryId?.name !== ""
                                ? categoryId.name
                                : "Select category"}
                            </div>
                            <ul
                              id="SelectCate"
                              className="dropdown-options"
                              style={{
                                display: categoryId.isActive ? "block" : "none",
                              }}
                            >
                              <li value="" disabled>
                                Select category
                              </li>
                              {categories &&
                                categories?.map((cat) => (
                                  <li
                                    onClick={() => {
                                      handleTopCategory(cat);
                                    }}
                                    style={{ fontFamily: "Poppins" }}
                                    id={cat.id}
                                    key={cat.id}
                                    value={cat.name}
                                  >
                                    {cat.name}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        {/* <Controller
                        name="category"
                        control={control}
                        className="form-control w-100"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, ...field } }) => (
                          <select
                            {...field}
                            required={true}
                            onChange={handleTopCategory}
                          >
                            <option value="" disabled>
                              Select category
                            </option>
                            {categories &&
                              categories?.map((cat) => (
                                <option
                                  style={{ fontFamily: "Poppins" }}
                                  // className="adddetail_heading_cat"
                                  id={cat.id}
                                  key={cat.id}
                                  value={cat.name}
                                >
                                  {cat.name}
                                </option>
                              ))}
                          </select>
                        )}
                      /> */}
                        {/* <span style={{ color: "red", fontSize: 14 }}>
                        {errors?.category?.message}
                      </span> */}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          zIndex: "8",
                        }}
                        className="inputHeader w-75 me-auto ms-auto"
                      >
                        <p>Select Topic:*</p>
                        <div className="basic-cate">
                          <div className="dropdown">
                            <div
                              className="cate-first"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setTopicId((prevState) => ({
                                  ...prevState,
                                  isActive: !topicId.isActive,
                                }));
                                setCategoryId((prevState) => ({
                                  ...prevState,
                                  isActive: false,
                                }));
                              }}
                            >
                              {topicId?.name !== ""
                                ? topicId?.name
                                : "Select topic"}
                            </div>
                            <ul
                              id="SelectCate"
                              className="dropdown-options"
                              style={{
                                display: topicId.isActive ? "block" : "none",
                              }}
                            >
                              <li value="" disabled>
                                Select topic
                              </li>
                              {topics &&
                                topics?.map((cat) => (
                                  <li
                                    onClick={() => {
                                      handleTopics(cat);
                                    }}
                                    style={{ fontFamily: "Poppins" }}
                                    id={cat.id}
                                    key={cat.id}
                                    value={cat.name}
                                  >
                                    {cat.name}
                                  </li>
                                ))}
                              <li
                                value="newTopic"
                                onClick={() => {
                                  handleTopics("newTopic");
                                }}
                                style={{ fontFamily: "Poppins" }}
                              >
                                Select new topic
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <Controller
                        name="category"
                        control={control}
                        className="form-control w-100"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, ...field } }) => (
                          <select
                            {...field}
                            required={true}
                            onChange={handleTopCategory}
                          >
                            <option value="" disabled>
                              Select category
                            </option>
                            {categories &&
                              categories?.map((cat) => (
                                <option
                                  style={{ fontFamily: "Poppins" }}
                                  // className="adddetail_heading_cat"
                                  id={cat.id}
                                  key={cat.id}
                                  value={cat.name}
                                >
                                  {cat.name}
                                </option>
                              ))}
                          </select>
                        )}
                      /> */}
                        {/* <span style={{ color: "red", fontSize: 14 }}>
                        {errors?.category?.message}
                      </span> */}
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <h2>2</h2>
                      <div className="inputHeader">
                        <p>
                          {video_type === "youtube"
                            ? "Add Video url:*"
                            : "Video from file:"}
                        </p>

                        {video_type === "youtube" ? (
                          <div className="w-75 m-auto">
                            <input
                              type="text"
                              id="video_url"
                              className="form-control w-100"
                              placeholder="Add YouTube video url here"
                              {...register("video_url", {
                                required: {
                                  value: true,
                                  message: "Video url is required.",
                                },
                                pattern: {
                                  value:
                                    /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?(?:[\w=&-]+)?v=([\w-]+)|([\w-]+))(?:&(?:[\w=&-]+))?$/,
                                  message: "Please add in correct format.",
                                },
                              })}
                              //onChange={(e) => handleVideoChange(e.target.value)}
                            />
                            <span style={{ color: "red", fontSize: 14 }}>
                              {errors?.video_url?.message}
                            </span>
                          </div>
                        ) : (
                          <div className="w-75 list-image">
                            <input
                              type="file"
                              accept="video/*"
                              className="form-control add_img_btn w-100"
                              required={true}
                              onChange={(e) => {
                                setValue("upload_video", e.target.files[0]);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <AppButton
                        type="button"
                        className="adddetail_btn w-75"
                        onClick={() => {
                          if (video_type === "youtube") {
                            setVideo_type("media");
                            setValue("video_url", "");
                          } else {
                            setVideo_type("youtube");
                            setValue("upload_video", "");
                          }
                        }}
                      >
                        Toggle Video Type
                      </AppButton>
                      <div className="inputHeader">
                        <p>Add Producer/Presenter:*</p>
                        <div className="w-75 m-auto">
                          <input
                            type="text"
                            name="title"
                            className="form-control w-100"
                            placeholder="Add name of video producer"
                            {...register("producer", {
                              required: {
                                value: true,
                                message: "Producer/Presenter Name is required",
                              },
                            })}
                          />
                          <span style={{ color: "red", fontSize: 14 }}>
                            {errors?.producer?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <h2>3</h2>

                      {thumbnail ? (
                        <div className="edit-image">
                          <img
                            src={
                              thumbnailType === "file"
                                ? URL.createObjectURL(thumbnail)
                                : thumbnail
                            }
                            alt=""
                          />
                          <button
                            type="button"
                            className="adddetail_btn w-75"
                            onClick={() => {
                              setThumbnail("");
                            }}
                          >
                            Remove Image
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="inputHeader">
                            <p>Add Video Image:*</p>
                            <div className="list-image">
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                className="form-control add_img_btn w-75 m-auto"
                                placeholder="Add image from file"
                                onChange={(e) => {
                                  onFileChange(e, "thumbnail");
                                }}
                              />
                            </div>
                          </div>

                          <div className="inputHeader">
                            <p>...or</p>
                          </div>
                          <div className="border py-3 border-light mb-5">
                            <div className="inputHeader">
                              <p>Add 11-digit video ID from url:*</p>
                              <div className="w-75 m-auto">
                                <input
                                  type="text"
                                  name="youtubeId"
                                  className="form-control w-100"
                                  placeholder="xxxxxxxxxxx"
                                  onChange={(e) => {
                                    setYouTubeId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="adddetail_btn w-75"
                              style={{
                                background: activeButton === "max" && "#f1b850",
                              }}
                              onClick={() => {
                                if (youTubeId) {
                                  setThumbnail(
                                    `https://img.youtube.com/vi/${youTubeId}/maxresdefault.jpg`
                                  );
                                  setThumbnailType("image");
                                  setActiveButton("max");

                                  //setVideoImageModal(true);
                                } else {
                                  alert("Please add 11 digit video id.");
                                }
                              }}
                            >
                              Click for save as <br /> maxresdefault
                            </button>
                            <button
                              type="button"
                              className="adddetail_btn w-75"
                              style={{
                                background: activeButton === "hq" && "#f1b850",
                              }}
                              onClick={() => {
                                if (youTubeId) {
                                  setThumbnail(
                                    `https://i.ytimg.com/vi/${youTubeId}/hqdefault.jpg`
                                  );
                                  setThumbnailType("image");
                                  setActiveButton("hq");
                                  //   setVideoImageModal(true);
                                } else {
                                  alert("Please add 11 digit video id.");
                                }
                              }}
                            >
                              Click for save as <br />
                              hqdefault
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {isLoad ? (
                    <Loader />
                  ) : (
                    <div className="form-outline adddetail_btns v2-new">
                      <AppButton
                        type="button"
                        className="green-btn"
                        onClick={() => setViewModal(true)}
                      >
                        View
                      </AppButton>

                      <AppButton
                        type="submit"
                        className="green-btn"
                        disabled={!isDirty && !isValid}
                        onClick={() => {}}
                      >
                        Update
                      </AppButton>
                      <AppButton
                        type="button"
                        className="green-btn"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Cancel
                      </AppButton>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default EditCourse;
