import { createSlice } from "@reduxjs/toolkit";

const courseDetailsSlice = createSlice({
  name: "courseDetails",
  initialState: {},
  reducers: {
    storeBasicDetails: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { storeBasicDetails } = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
