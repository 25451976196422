import React from "react";
import { Routes, Route } from "react-router-dom";

import "../assets/css/App.css";
import "../assets/css/style.css";

import {
  Cateogories,
  Home,
  Landing,
  Login,
  MyCourse,
  Signup,
  SubCategoryList,
  Video,
  VideoListings,
  Topic,
} from "../pages";
import { MaintenancePage, ProtectedRoute, NotFound } from "../common";
import CreateCourse from "../pages/CreateCourse/CreateCourse";
import ViewCourse from "../pages/CreateCourse/ViewCourse";
import CourseViewPage from "../pages/CreateCourse/CourseViewPage";
import Dummy from "../pages/Dummy";
import EditCourse from "../pages/CreateCourse/EditCourse";

const Navigation = () => {
  const isMaintenanceMode = false;
  const renderRoutes = () => {
    if (isMaintenanceMode) {
      return <Route path="*" element={<MaintenancePage />} />;
    } else {
      return (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/categories" element={<Cateogories />} />
          <Route
            path="/subCategoryList/:id/:topicId"
            element={<SubCategoryList />}
          />
          <Route path="/topic/:id" element={<Topic />} />
          <Route
            path="/video-listings/:type/:searchValue"
            element={<VideoListings />}
          />
          <Route path="/video/:id" element={<Video />} />
          <Route
            path="/basicDetails"
            element={
              <ProtectedRoute>
                <CreateCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editCourse/:id"
            element={
              <ProtectedRoute>
                <EditCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/myCourse"
            element={
              <ProtectedRoute>
                <MyCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/buildCourse"
            element={
              <ProtectedRoute>
                <Landing />
              </ProtectedRoute>
            }
          />
          <Route path="/ViewCourse/:id" element={<ViewCourse />} />
          <Route path="/CourseViewPage/:id" element={<CourseViewPage />} />
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/testing" element={<Dummy />} />
        </>
      );
    }
  };

  return <Routes>{renderRoutes()}</Routes>;
};

export default Navigation;
