/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import {
  getAuthorCourse,
  getProducerData,
  getSearchResult,
  getTopCourses,
  getTopics,
} from "../../api/request";
import { AppLink, Loader } from "../../common";
import { Next, Prev } from "../../assets/images";

export default function VideoListings() {
  const { type, searchValue } = useParams();

  const [videoData, setVideoData] = useState([]);
  const [searchTypeGlobal, setSearchTypeGlobal] = useState("");
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [sort, setSort] = useState("none");
  const [total, setTotal] = useState("");

  useEffect(() => {
    getVideoList();
  }, [type, searchValue]);

  const getVideoList = async () => {
    if (type) {
      if (type === "top20") {
        setIsLoading(true);
        const result = await getTopCourses();
        if (result) {
          setVideoData(result?.data);
          setTotal(result?.data?.length);
          setSearchTypeGlobal(<div>Today's Top 20 Micro-Courses</div>);
          setIsLoading(false);
        }
      } else if (type === "search") {
        setIsLoading(true);
        const result = await getSearchResult(searchValue, currentPage);

        if (result?.data?.length > 0) {
          setVideoData(result?.data);
          setLastPage(result?.last_page);
          setSearchTypeGlobal(
            <div>
              Search Results of "{searchValue}"{" "}
              <span style={{ fontSize: 20, color: "#fff" }}>
                [showing total {result?.total} results]
              </span>
            </div>
          );
          setTotal(result?.total);
          setIsLoading(false);
        } else {
          setSearchTypeGlobal("");
          setVideoData([]);
          setIsLoading(false);
        }
      } else if (type === "producer") {
        const result = await getProducerData(searchValue, currentPage);
        if (result?.data?.data?.length > 0) {
          setVideoData(result?.data?.data);
          setLastPage(result?.data?.last_page);
          setSearchTypeGlobal(
            <div>
              Search Results of "{searchValue}"{" "}
              <span style={{ fontSize: 20, color: "#fff" }}>
                [showing total {result?.data?.total} results]
              </span>
            </div>
          );
          setTotal(result?.data?.total);
          setIsLoading(false);
        } else {
          setSearchTypeGlobal("");
          setVideoData([]);
          setIsLoading(false);
        }
      } else if (type === "author") {
        const parts = searchValue.split("&");
        const result = await getAuthorCourse(parts[0], currentPage);
        if (result?.data?.data?.length > 0) {
          setVideoData(result?.data?.data);
          setLastPage(result?.data?.last_page);
          setSearchTypeGlobal(
            <div>
              Search Results of "{parts[1]}"{" "}
              <span style={{ fontSize: 20, color: "#fff" }}>
                [showing total {result?.data?.total} results]
              </span>
            </div>
          );
          setTotal(result?.data?.total);
          setIsLoading(false);
        } else {
          setSearchTypeGlobal("");
          setVideoData([]);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        alert("Something Went Wrong!");
      }
    }
  };

  const loadMoreData = async () => {
    let result;
    if (type === "search") {
      result = await getSearchResult(searchValue, currentPage + 1);
      if (result?.data?.length > 0) {
        const newData = [...videoData, ...result?.data];
        setVideoData(newData);
        setCurrentPage(currentPage + 1);
      }
    } else if (type === "author") {
      const parts = searchValue.split("&");
      const result = await getAuthorCourse(parts[0], currentPage + 1);
      if (result?.data?.data?.length > 0) {
        const newData = [...videoData, ...result?.data?.data];
        setVideoData(newData);
        setCurrentPage(currentPage + 1);
      }
    } else {
      result = await getProducerData(searchValue, currentPage + 1);
      if (result?.data?.data?.length > 0) {
        const newData = [...videoData, ...result?.data?.data];
        setVideoData(newData);
        setCurrentPage(currentPage + 1);
      }
    }
  };

  function videoSort() {
    const sorted = [...videoData].sort((a, b) => b.view_count - a.view_count);
    setVideoData(sorted);
  }

  function videoSortAlpha() {
    const sorted = [...videoData].sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setVideoData(sorted);
  }

  function SampleNextArrow(props) {
    const { onClick, className } = props;
    return <img alt="a" className={className} src={Prev} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { onClick, className } = props;

    return <img alt="a" className={className} src={Next} onClick={onClick} />;
  }

  const carouselSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section className="middle-part video-list video-contain">
            <div className="container">
              {videoData && videoData?.length > 0 && (
                <div className="row justify-content-between">
                  {searchTypeGlobal && (
                    <div className="col-md-6">
                      <h2 className="category-topic m-0">
                        {searchTypeGlobal}{" "}
                      </h2>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="btop_filer">
                      <form noValidate="" className="">
                        <div className="border-border">
                          <div className="dropdown">
                            <a
                              target="_self"
                              onClick={() => {
                                if (sort === "none") {
                                  setSort("block");
                                } else {
                                  setSort("none");
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Sort
                            </a>
                            <div
                              id="sortSearch"
                              className="dropdown-options"
                              style={{ display: sort }}
                            >
                              <a
                                target="_self"
                                onClick={() => {
                                  videoSort();
                                  setSort("none");
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Sort (by views)
                              </a>
                              <a
                                target="_self"
                                onClick={() => {
                                  videoSortAlpha();
                                  setSort("none");
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Sort (A-Z title)
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>

                      {videoData?.length > 0 &&
                        (show ? (
                          <div
                            className="view-btn"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            View All ({total && total})
                          </div>
                        ) : null)}
                    </div>
                  </div>
                </div>
              )}

              {videoData && show ? (
                <div className="row">
                  <div
                    className="col-md-12 product-video-box"
                    id="galleries-list"
                  >
                    <div className="col-md-12">
                      <div className="wrapper">
                        <Slider {...carouselSettings} initialSlide={0}>
                          {videoData &&
                            videoData.map((video, ind) => {
                              const {
                                id,
                                thumbnail,
                                title,
                                producer,
                                view_count,
                              } = video;
                              return (
                                <div
                                  className="px-2 rel_position"
                                  key={`${id}_slider_${ind}`}
                                >
                                  <AppLink
                                    to={`/ViewCourse/${id}`}
                                    onClick={() => {}}
                                  >
                                    <div className="thumbnail-box">
                                      <img
                                        style={{ objectFit: "fill" }}
                                        src={thumbnail}
                                        height="250px"
                                        width="80%"
                                        alt="product-img"
                                      />
                                    </div>
                                    <div className="thumbnail-title">
                                      {title}
                                      <span className="thumb_title">
                                        {title}
                                      </span>
                                    </div>
                                  </AppLink>
                                  <div className="row justify-content-between">
                                    <div className="col-auto">
                                      <div className="sub-title-1">
                                        {producer}
                                        <span className="produ_hover prod_2">
                                          {producer}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      <div className="sub-title-2">
                                        {view_count > 0 ? view_count : 0} Views
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-4">
                  {videoData &&
                    videoData?.map((video, i) => (
                      <div
                        className="col-md-3 mt-4"
                        key={`${video.id}_list_${i}`}
                      >
                        <div className="rel_position rel2">
                          <AppLink
                            to={"/ViewCourse/" + video.id}
                            onClick={() => {}}
                          >
                            <div
                              className="thumbnail-box"
                              style={{ border: "1px solid white" }}
                            >
                              <img
                                style={{ objectFit: "fill" }}
                                src={video.thumbnail}
                                height="250px"
                                width="100%"
                                alt="product-img"
                              />
                            </div>
                            <div className="thumbnail-title">
                              {video.title}
                              <span className="thumb_title">{video.title}</span>
                            </div>
                          </AppLink>
                          <div className="row justify-content-between">
                            <div className="sub-title-1">
                              {video.producer}
                              <span className="produ_hover prod_2">
                                {video.producer}
                              </span>
                            </div>
                            <div className="sub-title-2">
                              {video.view_count > 0 ? video.view_count : 0}{" "}
                              Views
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {videoData &&
                    videoData?.length > 0 &&
                    currentPage < lastPage && (
                      <div className="text-center mt-5">
                        <button
                          onClick={loadMoreData}
                          className="btn-primary btn-blue ng-star-inserted"
                        >
                          Show more
                        </button>
                      </div>
                    )}
                </div>
              )}
              {videoData?.length === 0 && (
                <div className="col-md-12">
                  <h3 className="error-msg">No matching records found</h3>
                </div>
              )}
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}
