import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { UserRegistration } from "../../api/request";
import Loader from "../../common/Loader";
import { showErrorMessage } from "../../common/Toast";

export default function Signup() {
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState(" ");

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const submitForm = async (data) => {
    setIsLoad(true);
    let payload = {
      ...data,
      password_confirmation: data.password,
      is_creator: "Yes",
      device_token: "",
      contact_no: "",
    };
    const result = await UserRegistration(payload);
    //setError(result?.response?.data?.message);
    if (result?.status) {
      const storeToken = localStorage.setItem("token", result?.token);
      const storeUserInfo = localStorage.setItem("user", result?.data);
      let name = result?.data?.name;
      let storeGuestName = localStorage.setItem("guestName", name?.toString());
      setIsLoad(false);
      navigate("/");
    } else {
      setIsLoad(false);
      // showErrorMessage(result?.message);
      showErrorMessage(result?.response?.data?.message);
    }
  };

  return (
    <>
      <div>Signup</div>
      <Header />

      <section className="middle-part">
        <div className="container">
          <div className="row">
            <div className="col-lg-3  col-md-3"></div>
            <div className="col-lg-6  col-md-6">
              <div className="login-box">
                <h3>My Courses: Register </h3>
                <div className="login-box-in">
                  <form id="form" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="Name">
                        Username
                      </label>
                      <input
                        type="text"
                        id="Name"
                        className="form-control"
                        placeholder="Add the name you want to appear on your courses"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.name?.message}
                      </span>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Add/change your email address"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "This is not a valid format!",
                          },
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.email?.message}
                      </span>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="Password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Password is required",
                          },
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.password?.message}
                      </span>
                    </div>

                    {/* <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="Password">
                        About us
                      </label>

                      <textarea
                        placeholder="About us"
                        maxLength={250}
                        className="form-control"
                        {...register("auth_bio")}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.auth_bio?.message}
                      </span>
                    </div> */}

                    {isLoad ? (
                      <Loader />
                    ) : (
                      <div className="form-outline green-btn-block">
                        <button
                          type="submit"
                          className="green-btn"
                          disabled={!isDirty && !isValid}
                        >
                          Signup
                        </button>
                        <button
                          type="button"
                          className="green-btn green-btn2"
                          onClick={() => navigate("/")}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-3"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
