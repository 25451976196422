import Spinner from "react-bootstrap/Spinner";

function CategoryLoader() {
  return (
    // <div className="d-flex justify-content-center">
    //   <Spinner animation="border" variant="danger" size="l" />
      
    // </div>
    <div className="loading position-relative text-center">
       Categories Loading 
    </div>
  );
}

export default CategoryLoader;
