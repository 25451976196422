import React, { useState, useEffect } from "react";

import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { dashboardData, getCategories } from "../../api/request";
import { logo2 } from "../../assets/images";
import { AppLink } from "../../common";
import CategoryLoader from "../../common/CategoryLoader";

export default function Cateogories() {
  const [homeData, setHomeData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    getCategoriesList();
  }, []);

  const getCategoriesList = async () => {
    setIsLoad(true);
    const categoriesResult = await getCategories();
    const homeResult = await dashboardData();
    if (categoriesResult) {
      setCategoryData(categoriesResult?.categories);
    }
    if (homeResult) {
      setHomeData(homeResult?.data?.info);
    }
    setIsLoad(false);
  };

  return (
    <>
      <Header />
      <section className="middle-part category-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-cate-head text-center">
                <h2>Categories</h2>
              </div>
              <div className="min-category-page">
                {isLoad ? (
                  <CategoryLoader />
                ) : (
                  <div className="row">
                    <div className="col-lg-12 category-li">
                      <ul>
                        {categoryData &&
                          categoryData.map((category, i) => (
                            <li key={category.id}>
                              <AppLink
                                to={"/subCategoryList/" + category.id + "/all"}
                                onClick={() => {}}
                              >
                                {category?.name} ({category?.total_course})
                              </AppLink>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="right-logo">
                <img src={logo2} width="100%" alt="logo 2" />
              </div>
              <div className="right-text">{homeData && homeData?.subTitle}</div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="right-box">
                <div className="top-hadding">
                  <div className="box-title" id="homePageTextBoardHeading">
                    On Build-a-Course, you can create your own micro-courses on
                    any subject in just a few minutes and then share them with
                    others. These include...
                  </div>
                </div>
                <div className="overflow categoies-scroller scroller">
                  <div id="homePageTextBoard">
                    <div className="row_one bounceInLeft">
                      Over 40 courses on Coaching and Learning Skills
                    </div>
                    <div className="row_two bounceInRight">
                      Over 80 courses on Communications
                    </div>
                    <div className="row_one bounceInLeft">
                      Over 60 courses on Conversation Skills
                    </div>
                    <div className="row_two bounceInRight">
                      Over 60 courses on Creative Thinking
                    </div>
                    <div className="row_one bounceInLeft">
                      Over 40 courses on Employee Skills
                    </div>
                    <div className="row_two bounceInRight">
                      Over 50 courses on The Front-Line Leader
                    </div>
                    <div className="row_one bounceInLeft">
                      Over 50 courses on Marketing and Sales
                    </div>
                    <div className="row_two bounceInRight">
                      Over 20 courses on Project Management
                    </div>
                    <div className="row_one bounceInLeft">
                      Over 40 courses on Qualities for Personal Success
                    </div>
                    <div className="row_two bounceInRight">
                      Over 50 courses on Stress Management
                    </div>
                    <div className="row_one bounceInLeft">
                      Over 20 courses on You and Your Career
                    </div>
                    <div className="row_two bounceInRight">
                      ... and many more
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
