import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  updateCourse,
  getAllCategories,
  deleteCourse,
  courseDetails,
  getTopicList,
} from "../../../api/request";
import { hideLoading, showLoading } from "../../../redux/slices/LoaderSlice";
import Loader from "../../../common/Loader";
import { showSuccessMessage } from "../../../common/Toast";

export default function EditBasicDetails() {
  const { isLoading } = useSelector((state) => state.loader);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);
  const [topCategories, setTopCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [thumbnail, setThumbnail] = useState("");

  const [topCategoryId, setTopCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [topicId, setTopicId] = useState(0);

  const [isCategoryInput, setIsCategoryInput] = useState(false);
  const [isTopicInput, setIsTopicInput] = useState(false);

  const [sideImg, setSideImg] = useState("");

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getCourseCategories();
  }, []);

  const getCourseCategories = async () => {
    const result = await getAllCategories();
    console.log("result", result);
    if (result) {
      setTopCategories(result?.data);
      getBasicDetails(result?.data);
    }
  };

  const getBasicDetails = async (categoriesData) => {
    dispatch(showLoading());
    let id = localStorage.getItem("courseId");
    let result = await courseDetails(id, "basic_info");
    if (result) {
      let categroyValue = categoriesData?.findIndex(
        (item) => item.name === result?.category
      );
      if (categroyValue !== -1) {
        setValue("category", categoriesData[categroyValue]?.name);
        const topicList = await getTopicList(categoriesData[categroyValue]?.id);
        console.log({ topicList });
        let topicValue = topicList?.data?.findIndex(
          (item) => item.name === result?.topic
        );
        if (topicValue !== -1) {
          setValue("topic", topicList?.data?.[topicValue]?.name);
        }
      }
      setValue("title", result?.title);
      setValue("sub_title", result?.sub_title);
      setValue("status", result?.status);

      setValue("tags", result?.tags);
      setTopCategoryId(result?.parent_category_id);
      setSubCategoryId(result?.category_id);
      setTopicId(result?.topic_id);
      setThumbnail(result?.thumbnail);
      setSideImg(result?.thumbnail);
      setIsSaved(true);
      dispatch(hideLoading());
    }
    dispatch(hideLoading());
  };

  const submitForm = async (data) => {
    let author = JSON.parse(localStorage.getItem("userDetails"));
    let payload = {
      ...data,
      // category: topCategoryId,
      // topic: topicId,
      author: author?.name,
      thumbnail_type: "media",
      thumbnail,
    };
    // console.log(payload);
    let id = localStorage.getItem("courseId");
    const result = await updateCourse(payload, id);
    console.log("update_course", result);
    if (result?.status) {
      showSuccessMessage(result?.message);
      getCourseCategories();
    }
  };

  const onFileChange = (event) => {
    setThumbnail(event.target.files[0]);
    setSideImg(URL.createObjectURL(event.target.files[0]));
  };

  const handleTopCategory = (val) => {
    const index = val.target.selectedIndex;
    const el = val.target.childNodes[index];
    const option = el.getAttribute("id");
    fetchTopics(option);
    setValue("category", val.target.value);
    setTopCategoryId(option);
    // const getIndex = topCategories.findIndex(
    //   (item) => item.name == val.target.value
    // );
    // if (getIndex !== -1) {
    //   setSubCategories(topCategories[getIndex]?.subcategories);
    //   setTopics([]);
    // }
  };

  const handleTopics = (val) => {
    const index = val.target.selectedIndex;
    const el = val.target.childNodes[index];
    const option = el.getAttribute("id");
    setValue("topic", val.target.value);
    setTopicId(option);
  };

  const fetchTopics = async (id) => {
    const result = await getTopicList(id);
    console.log("topics", result);
    if (result?.status) {
      setTopics(result?.data);
    }
  };

  const handleDelete = async () => {
    let id = localStorage.getItem("courseId");
    const result = await deleteCourse(id);
    console.log("delete course", result);
    if (result?.status) {
      localStorage.removeItem("courseId");
      showSuccessMessage(result?.message);
      navigate("/");
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="row">
            <h4 className="build_course_heading">
              CONFIRM/EDIT BASIC COURSE DETAILS
            </h4>
            <div className="col-md-8">
              <form
                className="buildCourse_form"
                id="form"
                onSubmit={handleSubmit(submitForm)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <p>Name of course:*</p>
                  <div className="w-75">
                    <input
                      type="text"
                      name="title"
                      className="form-control w-100"
                      onChange={(e) => alert(e.target.value)}
                      placeholder="Add name/title of course"
                      {...register("title", {
                        required: {
                          value: true,
                          message: "Course Name is required",
                        },
                      })}
                    />
                    <span style={{ color: "red", fontSize: 14 }}>
                      {errors?.title?.message}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <p>Sub-title:*</p>
                  <div className="w-75">
                    <input
                      type="text"
                      name="sub_title"
                      className="form-control w-100"
                      placeholder="Add sub-title of course"
                      {...register("sub_title", {
                        required: {
                          value: true,
                          message: "Sub-Title is required",
                        },
                      })}
                    />
                    <span style={{ color: "red", fontSize: 14 }}>
                      {errors?.sub_title?.message}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <p>Listing image:*</p>
                  <div className="list-image">
                    <input
                      type="file"
                      className="form-control add_img_btn"
                      placeholder="Add image from file"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <p>Tags :</p>
                  <div className="w-75">
                    <input
                      type="text"
                      name="tags"
                      className="form-control w-100"
                      placeholder="Add tags (optional)"
                      {...register("tags", {
                        required: {
                          value: true,
                          message: "Tags is required",
                        },
                      })}
                    />
                    <span style={{ color: "red", fontSize: 14 }}>
                      {errors?.tags?.message}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <p>Category:*</p>
                  {isCategoryInput ? (
                    <div className="w-75">
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Add new Category"
                        onChange={(e) => {
                          setTopCategoryId(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <Controller
                      name="category"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, ...field } }) => (
                        <select {...field} onChange={handleTopCategory}>
                          <option id="" value="">
                            Select category from dropdown list
                          </option>
                          {topCategories &&
                            topCategories.map((cat) => (
                              <option id={cat.id} key={cat.id} value={cat.name}>
                                {cat.name}
                              </option>
                            ))}
                        </select>
                      )}
                    />
                  )}
                </div>
                {/* <div className="cor_sec">
                  <button
                    type="button"
                    onClick={() => {
                      if (activeInput === "category") {
                        setTopCategoryId("");
                        setActiveInput("");
                      } else {
                        setActiveInput("category");
                      }
                    }}
                    className="add_new_btn"
                  >
                    Add new category
                  </button>
                </div> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <p>Topic:* </p>
                  {isTopicInput ? (
                    <div className="w-75">
                      <input
                        type="text"
                        name="sd"
                        className="form-control w-100"
                        placeholder="Add new topic"
                        onChange={(e) => {
                          setTopicId(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <Controller
                      name="topic"
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <select {...field} onChange={handleTopics}>
                          <option value="">
                            Select topic from dropdown list
                          </option>
                          {topics &&
                            topics.map((cat) => (
                              <option id={cat.id} key={cat.id} value={cat.name}>
                                {cat.name}
                              </option>
                            ))}
                        </select>
                      )}
                    />
                  )}
                </div>
                {/* <div className="cor_sec">
                  <button
                    type="button"
                    className="add_new_btn"
                    onClick={() => {
                      if (activeInput === "topic") {
                        setTopicId("");
                        setActiveInput("");
                      } else {
                        setActiveInput("topic");
                      }
                    }}
                  >
                    Add new topic
                  </button>
                </div> */}

                <div className="form-outline edit_course_btns mt-4">
                  <button
                    type="submit"
                    disabled={!isDirty && !isValid}
                    className="green-btn"
                  >
                    Confirm and Add Course Pages
                  </button>
                  <button
                    type="button"
                    className="green-btn green-btn2"
                    // onClick={() => navigate("/frontPage")}
                  >
                    View on Front Page
                  </button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="green-btn delete-btn"
                  >
                    Delete
                  </button>
                  <button type="button" className="green-btn back-btn">
                    Back
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              {sideImg && (
                <>
                  <div className="course_img mb-4">
                    <img src={sideImg} alt="course_image" />
                  </div>
                  <div className="text-center mb-4">
                    <a
                      className="btn dlt_img"
                      type="button"
                      href="#"
                      onClick={() => {
                        setSideImg("");
                        setThumbnail("");
                      }}
                    >
                      Delete Image
                    </a>
                  </div>
                </>
              )}

              <div className="course_notes">
                <h4>Notes for Course Creators:</h4>
                <ul>
                  <li>
                    The “Confirm and Add Course Pages” button creates the course
                    title page, adds the course to your “Courses Added” list on
                    your “My Courses” page (linked from header) and takes you to
                    the “Add Course Pages” page.
                  </li>
                  <li>
                    The “Back” button takes you to your pre-course-building
                    page.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
