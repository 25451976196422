import React from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

export default function Video() {
  const { id } = useParams();

  return (
    <div>
      <Header />
      <h3>Read Course :{id}</h3>
      <Footer />
    </div>
  );
}
