import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { SocialLogin, UserLogin } from "../../api/request";
import Loader from "../../common/Loader";
import { showErrorMessage } from "../../common/Toast";
import AppButton from "../../common/AppButton";
import { Google, FB } from "../../assets/images";

export default function Login() {
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [user, setUser] = useState([]);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("profile res", res);
          if (res?.data) {
            googleLogin(res?.data?.name, res?.data?.email);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const submitForm = async (data) => {
    let payload = {
      email: data?.email,
      password: data?.password?.trim(),
      device_token: "",
    };
    const result = await UserLogin(payload);
    if (result?.status) {
      localStorage.setItem("token", result?.token);
      localStorage.setItem("userDetails", JSON.stringify(result?.data));
      let name = result?.data?.name;
      localStorage.setItem("guestName", name?.toString());
      localStorage.setItem("bio", result?.data?.auth_bio);
      setIsLoad(false);
      navigate("/");
    } else {
      setIsLoad(false);
      showErrorMessage(result?.response?.data?.message);
    }
  };

  async function responseFacebook(response) {
    console.log(response, "fb");
    if (response.status !== "unknown") {
      let payload = {
        name: response?.name,
        email: response?.email,
        device_token: "",
      };
      const result = await SocialLogin(payload);
      if (result?.status) {
        localStorage.setItem("token", result?.token);
        localStorage.setItem("userDetails", JSON.stringify(result?.data));
        let name = result?.data?.name;
        localStorage.setItem("guestName", name?.toString());
        localStorage.setItem("bio", result?.data?.auth_bio);
        setIsLoad(false);
        navigate("/");
      } else {
        setIsLoad(false);
        showErrorMessage(result?.response?.data?.message);
      }
    } else {
      console.log("Facebook login failed!");
    }
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log({ codeResponse });
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const googleLogin = async (name, email) => {
    let payload = {
      name,
      email,
      device_token: "",
    };
    const result = await SocialLogin(payload);
    console.log("google", result);
    if (result?.status) {
      localStorage.setItem("token", result?.token);
      localStorage.setItem("userDetails", JSON.stringify(result?.data));
      let name = result?.data?.name;
      localStorage.setItem("guestName", name?.toString());
      localStorage.setItem("bio", result?.data?.auth_bio);
      setIsLoad(false);
      navigate("/");
    } else {
      setIsLoad(false);
      showErrorMessage(result?.response?.data?.message);
    }
  };

  return (
    <>
      <div>Login</div>
      <Header />

      <section className="middle-part">
        <div className="container">
          <div className="row">
            <div className="col-lg-3  col-md-3"></div>
            <div className="col-lg-6  col-md-6">
              <div className="login-box">
                <h3>My Courses: Login Details</h3>
                <div className="login-box-in">
                  <form id="form" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="Email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="Email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "This is not a valid format!",
                          },
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.email?.message}
                      </span>
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Password is required",
                          },
                        })}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.password?.message}
                      </span>
                    </div>
                    <div className="social_icons">
                      <FacebookLogin
                        autoLoad={false}
                        appId="927146551879269"
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={(props) => (
                          <img
                            onClick={props.onClick}
                            className={"fbButton"}
                            src={FB}
                            alt="fb"
                          />
                        )}
                      />
                      <div onClick={() => login()}>
                        <img src={Google} alt="Google" />
                      </div>
                    </div>

                    {isLoad ? (
                      <Loader />
                    ) : (
                      <div className="form-outline green-btn-block">
                        <AppButton
                          type="submit"
                          onClick={() => {}}
                          className="green-btn"
                          disabled={!isDirty && !isValid}
                        >
                          Login
                        </AppButton>
                        <AppButton
                          type="button"
                          className="green-btn green-btn2"
                          onClick={() => navigate("/")}
                        >
                          Cancel
                        </AppButton>
                      </div>
                    )}
                  </form>

                  <div className="m-3 " onClick={() => navigate("/signup")}>
                    <h3 className="text-decoration-underline pe-auto reg">
                      Registration
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-3"></div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
