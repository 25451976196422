import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { Report, View, Copy, Share } from "../assets/images";
import AppButton from "./AppButton";
import CopyModal from "./CopyModal";

function ViewCourseModal({
  intro = "Course Intro",
  title = "Course title",
  category = "Course category",
  topic = "Course topic",
  //author = "author name",
  category_id = "1",
  topic_id = "2",
  producer = "Unknown",
  thumbnail,
  thumbnailType,
  video_type = "youtube",
  video_url = "https://youtu.be/E568ctRIdLE",
  handleHide,
}) {
  const name = useSelector((state) => state.guestName);

  const navigate = useNavigate();

  const [toggleView, setToggleView] = useState(true);
  const [showIntro, setShowIntro] = useState("");
  const [clipboardText, setClipboardText] = useState("");

  function getYouTubeId(url) {
    let videoId = "";
    const regex =
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:$|&|\?|#)/;
    const match = url?.match(regex);
    if (match && match[1]) {
      videoId = match[1];
    }

    return videoId;
  }

  const handleCopyText = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Error copying text: ", err);
    }
    closeModal();
  };

  const closeModal = () => {
    setShowIntro("");
  };

  return (
    <div
      className="raund-modal modal_main raund_m1"
      id="raund-user"
      style={{ zIndex: 999 }}
    >
      <div className="modal-dialog modal_popup">
        <div className="modal-content">
          <div className="modal-body modal-inner-box">
            {showIntro === "intro" ? (
              <CopyModal
                title="Course Intro"
                content={intro}
                handleClose={closeModal}
              />
            ) : showIntro === "embed" ? (
              <CopyModal
                title={"Embed Code"}
                content={`<iframe width="420" height="345" src=${window?.location?.href} title="Build-a-course"></iframe>`}
                handleCopy={() => {
                  let text = `<iframe
                                width="420"
                                height="345"
                                src=${window?.location?.href}
                                title="Build-a-course"
                            ></iframe>`;
                  handleCopyText(text);
                }}
                handleClose={closeModal}
              />
            ) : showIntro === "share" ? (
              <CopyModal
                title="Share Course"
                content={window?.location?.href}
                handleCopy={() => {
                  handleCopyText(window?.location?.href);
                }}
                handleClose={closeModal}
              />
            ) : showIntro === "notes" ? (
              <CopyModal
                title="Add Your Notes"
                onChange={(e) => setClipboardText(e.target.value)}
                content={clipboardText}
                handleCopy={() => {
                  handleCopyText(clipboardText);
                }}
                handleClose={closeModal}
              />
            ) : showIntro === "report" ? (
              <CopyModal
                title="Report Missing Video"
                content={
                  'A "Report Missing Video" message has now been sent to Build a course team. Thank you!'
                }
                handleClose={closeModal}
              />
            ) : null}
            <section className="middle-part video-page">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="min-category-page zoomInDown">
                      <h2>{title}</h2>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4">
                    <div className="pe-xxl-0 pe-xl-4 pe-0 h-100">
                      <div className="cat-box-mian bounceInLeft">
                        <div className="cat-box-block">
                          <div className="cat-box-block-inner">
                            <h3 style={{ textAlign: "left" }}>Category:</h3>
                            <div className="sub-block">{category}</div>
                          </div>
                        </div>
                        <div className="cat-box-block">
                          <div className="cat-box-block-inner">
                            <h3 style={{ textAlign: "left" }}>Topic:</h3>
                            <div className="sub-block">{topic}</div>
                          </div>
                        </div>
                        <div className="cat-box-block">
                          <div className="cat-box-block-inner">
                            <h3 style={{ textAlign: "left" }}>
                              Course added by:
                            </h3>
                            <div className="sub-block">{name}</div>
                          </div>
                        </div>
                        <div className="cat-box-block mb-0">
                          <div className="cat-box-block-inner description-tooltip">
                            <h3 style={{ textAlign: "left" }}>
                              Video made by:
                            </h3>
                            <div className="sub-block">{producer}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-4">
                    <div className="youtube-videos position-relative h-auto">
                      {toggleView ? (
                        <img
                          width="100%"
                          style={{ height: "400px", objectFit: "contain" }}
                          alt="product-img"
                          src={
                            thumbnailType === "file"
                              ? URL.createObjectURL(thumbnail)
                              : thumbnail
                          }
                        />
                      ) : (
                        <>
                          {video_type === "media" ? (
                            <video
                              src={video_url}
                              width="750"
                              height="500"
                              controls
                            ></video>
                          ) : (
                            <YouTube
                              style={{
                                height: "400px",
                                objectFit: "contain",
                              }}
                              videoId={getYouTubeId(video_url)}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <AppButton
                        type="button"
                        className="viewdetail-btn"
                        onClick={() => setShowIntro("intro")}
                      >
                        Course Intro
                      </AppButton>
                      <AppButton
                        type="button"
                        className="viewdetail-btn"
                        onClick={() => setToggleView(!toggleView)}
                      >
                        {toggleView ? "Play Video" : "View Image"}
                      </AppButton>
                      <AppButton
                        type="button"
                        className="viewdetail-btn"
                        onClick={async () => {
                          setShowIntro("notes");
                        }}
                      >
                        Add your notes
                      </AppButton>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4">
                    <div className="pe-xxl-0 pe-xl-4 pe-0 h-100">
                      <div className="cat-box-mian bounceInRight">
                        <div className="cat-box-block">
                          <div
                            className="cat-box-block-inner box-right"
                            onClick={() =>
                              navigate(
                                "/subCategoryList/" +
                                  category_id +
                                  "/" +
                                  topic_id
                              )
                            }
                          >
                            <img _ngcontent-vug-c36="" src={View} alt=""></img>
                            <h3 style={{ textAlign: "left" }}>
                              View other courses in this topic
                            </h3>
                          </div>
                        </div>
                        <div className="cat-box-block">
                          <div
                            className="cat-box-block-inner box-right"
                            //onClick={() => setShowIntro("embed")}
                          >
                            <img _ngcontent-vug-c36="" src={Copy} alt=""></img>
                            <h3 style={{ textAlign: "left" }}>
                              Copy embed code
                            </h3>
                          </div>
                        </div>
                        <div className="cat-box-block">
                          <div
                            className="cat-box-block-inner box-right"
                            //onClick={() => setShowIntro("share")}
                          >
                            <img _ngcontent-vug-c36="" src={Share} alt=""></img>
                            <h3 style={{ textAlign: "left" }}>
                              Share with others
                            </h3>
                          </div>
                        </div>
                        <div className="cat-box-block">
                          <div
                            className="cat-box-block-inner box-right"
                            onClick={() => {
                              //handleVideoReport();
                              //setShowIntro("report");
                            }}
                          >
                            <img
                              _ngcontent-vug-c36=""
                              src={Report}
                              alt=""
                            ></img>
                            <h3 style={{ textAlign: "left" }}>
                              Report video issue
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="text-center">
              <AppButton
                data-bs-dismiss="modal"
                className="green-btn"
                onClick={handleHide}
              >
                Hide
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCourseModal;
