import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import ModelScreen from "./components/ModelScreen";
import Navigation from "./navigation/Navigation";
import { setName } from "./redux/slices/guestNameSlice";
import { isShow } from "./redux/slices/showModalSlice";

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isShow())
  }, [])


  const isVisible = useSelector((state) => state.showModal);

  return (
    <>
      <ModelScreen
        isShow={isVisible ? true : false}
        onPress={(name) => {
          localStorage.setItem("guestName", name);
          dispatch(setName(name));
          dispatch(isShow(false))

        }}
      />
      <Navigation />
    </>
  );
};

export default App;
