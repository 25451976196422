/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { googleLogout } from "@react-oauth/google";

import { logo, UserIcon, Pc, Logout, Play } from "../../assets/images";
import { AppLink, AppButton } from "../../common";
import { getName, setName } from "../../redux/slices/guestNameSlice";
import { isShow } from "../../redux/slices/showModalSlice";

function Header() {
  const dispatch = useDispatch();

  const name = useSelector((state) => state.guestName);

  const navigation = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getName());
  }, []);

  const submits = () => {
    if (searchQuery.trim() !== "") {
      navigation(`/video-listings/search/${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submits();
    }
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 my-auto">
              <div className="top-logo zoomInDown" id="logo">
                <AppLink to="/" onClick={() => {}}>
                  <img src={logo} alt="" />
                </AppLink>
              </div>
            </div>
            <div className="col-lg-9  col-md-8 header-actions ">
              <div className="header-categories bounceInLeft me-4">
                <div className="search-in">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <AppButton type="button" onClick={submits}>
                    <i className="fa fa-search"></i>
                  </AppButton>
                </div>
              </div>

              <div className="header-Category me-4">
                <AppLink
                  to="/categories"
                  className="Category-blog"
                  href="/categories"
                  target=""
                  onClick={() => {}}
                >
                  Categories
                </AppLink>
                {localStorage.getItem("token") !== null && (
                  <AppLink to="/myCourse" onClick={() => {}}>
                    <div className="tooltip1">
                      <span className="tooltiptext1">My Micro-Courses</span>
                      <a className="Category-2 ms-4" href="#">
                        <img src={Play} alt="pc2" />
                      </a>
                    </div>
                  </AppLink>
                )}
                <AppLink to="/basicDetails" onClick={() => {}}>
                  <div className="tooltip1">
                    <span className="tooltiptext1">Create a Course</span>
                    <a className="Category-2 ms-4" href="#">
                      <img src={Pc} alt="pc2" />
                    </a>
                  </div>
                </AppLink>
              </div>
              <div className="right-user-id bounceInLeft">
                <div className="top-user-btn" id="userId">
                  <div className="user_icon">
                    <img src={UserIcon} width="50px" alt="user icon" />
                  </div>
                  <div className="user_name">{name}</div>
                </div>
              </div>
              {localStorage.getItem("token") !== null ? (
                <div className="tooltip1">
                  <span className="tooltiptext1">Logout</span>
                  <a
                    className="Category-2 ms-4"
                    href="#"
                    onClick={() => {
                      localStorage.clear();
                      dispatch(setName(""));
                      navigation("/");
                      dispatch(isShow(true));
                      googleLogout();
                    }}
                  >
                    <img src={Logout} alt="pc2" />
                  </a>
                </div>
              ) : (
                <div className="tooltip1">
                  <span className="tooltiptext1">Login</span>
                  <a
                    className="Category-2 ms-4"
                    href="#"
                    onClick={() => {
                      navigation("/login");
                    }}
                  >
                    <img src={Logout} alt="pc2" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
