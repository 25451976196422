import React, { useState } from "react";
import sound from "../assets/audio/click.wav";

const ModelScreen = ({ isShow, onPress }) => {
  const [audio] = useState(new Audio(sound));
  const [error, setError] = useState(" ");
  const [name, setName] = useState("");

  const start = () => {
    audio.play();
    localStorage.setItem("guestName", name);
  };

  return isShow ? (
    <div
      className="raund-modal raund_m1"
      id="raund-user"
      style={{ zIndex: 999 }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body modal-inner-box  ">
            <div className="model-content">
              <h3>Guest user name</h3>
              <div className="ineer-popup-content user-input">
                <input
                  type="user-name"
                  className="form-control"
                  id="user-name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Guest user name"
                />
              </div>

              {name.length >= 3
                ? null
                : error && (
                    <div
                      style={{
                        textAlign: "center",
                        color: "dimgray",
                        paddingTop: 10,
                      }}
                      className="error"
                    >
                      {error}
                    </div>
                  )}

              <div className="ineer-popup-close-btn submit-popup">
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-green"
                  onClick={() => {
                    localStorage.setItem("guestName", name);
                    name.length === 0
                      ? setError("Name is required")
                      : onPress(name);
                    start();
                    console.log(name)
                  
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModelScreen;
