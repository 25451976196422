import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-inner-text">
              At KSA Training Ltd, we have a simple mission: "To make e-learning
              free, fun, and inspirational".
              <br /> We are:
              <a
                href="https://course.24livehost.com/mtl_build/#/"
                rel="noreferrer"
                target={"_blank"}
              >
                MTL for slides
              </a>
              <a
                href="https://course.24livehost.com/build/#/"
                rel="noreferrer"
                target={"_blank"}
              >
                Snippizz for videos
              </a>
              <a
                href="https://www.kwizzers.com/"
                rel="noreferrer"
                target={"_blank"}
              >
                Kwizzers for games
              </a>
              <a
                href="https://www.thesoftskillsquiz.com/"
                rel="noreferrer"
                target={"_blank"}
              >
                The Soft Skills Quiz for quizzes
              </a>
              <a
                href="https://unityteamnew.24livehost.com/vv/build_a_course/"
                rel="noreferrer"
                target={"_blank"}
              >
                Build-a-Course for micro-courses
              </a>
              <a
                href="https://course.24livehost.com/bizniz_build/#/"
                rel="noreferrer"
                target={"_blank"}
              >
                Biznizbooks for e-books
              </a>
              <br /> Copyright KSA Training Ltd 2022
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
