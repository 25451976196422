import React, { useState, useEffect } from "react";
import axios from "axios";

const CarouselWithSorting = () => {
  const [videoData, setVideoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    axios
      .get(
        `https://course.24livehost.com/api/buildcourse/topic/241?page=${currentPage}`
      )
      .then((response) => {
        setVideoData(response.data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadMoreData = () => {
    axios
      .get(
        `https://course.24livehost.com/api/buildcourse/topic/241?page=${
          currentPage + 1
        }`
      )
      .then((response) => {
        const newData = [...videoData, ...response.data.data.data];
        setVideoData(newData);
        setCurrentPage(currentPage + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="col-md-12">
      {videoData &&
        videoData?.map((video) => {
          return (
            <div>
              <img
                alt="thu"
                src={video?.thumbnail}
                style={{ objectFit: "fill" }}
                height="250px"
                width="30%"
              />
              <h4>{video?.title}</h4>
            </div>
          );
        })}
      <button onClick={loadMoreData}>View More</button>
    </div>
  );
};

export default CarouselWithSorting;
