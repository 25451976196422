import { createSlice } from '@reduxjs/toolkit';

const guestNameSlice = createSlice({
    name: 'guestName',
    initialState: '',
    reducers: {

        getName: (state) => {
            state = localStorage.getItem('guestName');;
            return state;
        },
        setName: (state, action) => {
            state = action.payload;
            localStorage.setItem('guestName', state);
            return state;
        },
    },
});

export const { setName, getName } = guestNameSlice.actions;
export default guestNameSlice.reducer;
