import React from "react";
import AppButton from "./AppButton";

const CopyModal = ({ title, content, handleCopy, handleClose, onChange }) => {
  const copyText = () => {
    // let text = document.getElementById("copied-content");
    // text.select();
    // text.setSelectionRange(0, 99999);
    // navigator.clipboard.writeText(text.value);
  };
  return (
    <div
      className="raund-modal raund_m1"
      id="raund-user"
      style={{ zIndex: 999 }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body modal-inner-box  ">
            <div className="model-content">
              <h3>{title}</h3>

              <div className="ineer-popup-close-btn submit-popup">
                <div className="d-flex ps-5 pe-5">
                  {title === "Add Your Notes" ? (
                    <textarea
                      id="copied-content"
                      className="note-textarea"
                      type="text"
                      rows={4}
                      value={content}
                      onChange={(e) => onChange(e)}
                    ></textarea>
                  ) : (
                    <p id="copied-content" className="text-light">
                      {content}
                    </p>
                  )}
                </div>
                {title === "Course Intro" ||
                title === "Report Video Issue" ? null : (
                  <AppButton
                    data-bs-dismiss="modal"
                    className="green-btn delete-btn"
                    onClick={() => {
                      copyText();
                      handleCopy();
                    }}
                  >
                    {title === "Add Your Notes" ? "Save" : "Copy"}
                  </AppButton>
                )}
                <AppButton
                  data-bs-dismiss="modal"
                  className="green-btn delete-btn"
                  onClick={handleClose}
                >
                  Close
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyModal;
