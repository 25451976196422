import React from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "./AppButton";

function ErrorModal({ errorMessage, onClick }) {
  const navigate = useNavigate();
  return (
    <div
      className="raund-modal raund_m1"
      id="raund-user"
      style={{ zIndex: 999 }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body modal-inner-box  ">
            <div className="model-content success_modal">
              <h5 className="text-white text-center mb-3">{errorMessage}</h5>

              <div className="form-outline adddetail_btns">
                <AppButton
                  type="button"
                  className="green-btn"
                  onClick={onClick}
                >
                  Close
                </AppButton>
                {/* <AppButton
                  className="green-btn"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Ok
                </AppButton> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
