/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import {
  EditBasicDetails,
  Introduction,
  KeyPoint,
  NotesPage,
} from "./basicDetails";
import { Quizzes, Videos } from "./coursePage";

const sections = [
  {
    id: "1",
    title: "Edit Course Details",
    subMenu: [],
    Component: <EditBasicDetails />,
  },
  {
    id: "2",
    title: "Introduction",
    subMenu: [],
    Component: <Introduction />,
  },
  {
    id: "3",
    title: "Key Points",
    subMenu: [],
    Component: <KeyPoint />,
  },
  {
    id: "4",
    title: "Video",
    subMenu: [],
    Component: <Videos />,
  },
  {
    id: "5",
    title: "Quiz",
    subMenu: [],
    Component: <Quizzes />,
  },
  {
    id: "6",
    title: "Notes Page",
    subMenu: [],
    Component: <NotesPage />,
  },
];

function Landing() {
  const [activeComponent, setActiveComponent] = useState(<EditBasicDetails />);
  const [activeMenu, setActiveMenu] = useState(0);
  const [creator, setCreator] = useState("");
  const [subMenus, setSubMenus] = useState(sections[0].subMenu);

  useEffect(() => {
    const getName = () => {
      let author = JSON.parse(localStorage.getItem("userDetails"));
      setCreator(author?.name);
    };
    getName();
  }, []);
  return (
    <div>
      <Header />
      <div className="container">
        <div className="d-flex justify-content-between">
          <h4 className="build_course_heading">
            {/* Name of Course: <span>What is Microlearning?</span> */}
          </h4>
          {creator && (
            <h4 className="build_course_heading ml-auto">
              Course Creator: <span>{creator}</span>
            </h4>
          )}
        </div>

        <div className="top_actions mb-3">
          <div className="row w-100">
            {sections &&
              sections.map((section) => {
                return (
                  <div
                    key={section.id}
                    className="col-auto"
                    onClick={() => {
                      setActiveComponent(section.Component);
                      setSubMenus(section.subMenu);
                      setActiveMenu("");
                    }}
                  >
                    <a className="btn add_btn" type="button" href="#">
                      {section.title}
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="course_topic pb-3 mb-4">
          {subMenus &&
            subMenus?.map((subMenu) => {
              return (
                <a
                  key={subMenu.id}
                  onClick={() => {
                    setActiveComponent(subMenu.Component);
                    setActiveMenu(subMenu.id);
                  }}
                  className={`course_name ${
                    activeMenu === subMenu.id && "active"
                  }`}
                  href="#"
                >
                  {subMenu.title}
                </a>
              );
            })}
        </div>
        {/* Component Render Section */}
        {activeComponent && activeComponent}
      </div>
    </div>
  );
}
export default Landing;
