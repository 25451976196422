import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import ScrollToTop from "./common/ScrollToTop";
import store from "./redux/store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="895224601600-6msv8g7bkhai42rg689g55emh231fous.apps.googleusercontent.com">
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter
        // basename="/vv/build_a_course/"
        // basename="/home/snippizz/build-a-course.com"
        >
          <ScrollToTop />
          <App />
        </BrowserRouter>
        <ToastContainer autoClose={2000} />
      </React.StrictMode>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
