import { configureStore } from "@reduxjs/toolkit";

import loaderReducer from "../slices/LoaderSlice";
import soundReducer from "../slices/soundSlice";
import courseDetailsReducer from "../slices/courseDetailsSlice";
import guestNameReducer from '../slices/guestNameSlice'
import showModalReducer from "../slices/showModalSlice";
const store = configureStore({
  reducer: {
    loader: loaderReducer,
    sound: soundReducer,
    courseDetails: courseDetailsReducer,
    guestName: guestNameReducer,
    showModal: showModalReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
