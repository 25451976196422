import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { saveIntroduction } from "../../../api/request";
import { showSuccessMessage } from "../../../common/Toast";

export default function Introduction() {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const submitForm = async (data) => {
    const course_id = localStorage.getItem("courseId");
    let payload = {
      ...data,
      course_id,
      section_title: "Introduction",
    };
    const result = await saveIntroduction(course_id, payload);
    console.log(result);
    if (result?.status) {
      showSuccessMessage(result?.message);
    }
  };

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-10 offset-md-1">
          <h4 className="build_course_heading">
            ADD/EDIT INTRODUCTION PAGE DETAILS
          </h4>
          <form
            className="buildCourse_form"
            id="form"
            onSubmit={handleSubmit(submitForm)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Edit or use default Section text:</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control w-100"
                  defaultValue="The introduction states the aim of this course, what you will learn, and how you can apply your learning immediately in your work."
                  {...register("section_description", { required: true })}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Edit or use default name:*</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control w-100"
                  defaultValue="Introduction to This Course"
                  {...register("title", { required: true })}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Edit or use default text for Introduction page:*</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={8}
                  className="form-control w-100"
                  defaultValue="Welcome to this mini-course from Build-a-Course.
                    The aim of this course is to give you a thorough 
                    understanding of micro-learning so that you are able to 
                    apply it to your own learning courses.
                    By the end of this course, you will be able to…
                    a. List 5 features of microlearning
                    b. State 3 ways in which microlearning can benefit you 
                    and your organisation
                    c. Demonstrate how you would convert a traditional 
                    course into a microlearning course
                    This course is designed to be self-paced so that you can 
                    move around the course as you want and whenever 
                    you want."
                  {...register("description", { required: true })}
                />
              </div>
            </div>

            <div className="form-outline w-75 ms-auto edit_course_btns mt-4">
              <button
                type="submit"
                disabled={!isDirty && !isValid}
                className="green-btn ms-5"
              >
                Save
              </button>
              <button type="button" className="green-btn green-btn2">
                View Course So Far
              </button>
              <button
                type="button"
                //onClick={handleDelete}
                className="green-btn delete-btn"
              >
                Publish
              </button>
              <button type="button" className="green-btn back-btn">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
