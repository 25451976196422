/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTopicData } from "../../api/endpoints";
import { getTopicListData } from "../../api/request";
import { AppLink } from "../../common";
import { Footer } from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Dropdown from "react-bootstrap/Dropdown";

const Topic = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [videoData, setVideoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [topicName, setTopicName] = useState("");
  const [total, setTotal] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [sort, setSort] = useState("none");

  useEffect(() => {
    getTopic();
  }, []);

  const getTopic = async () => {
    const result = await getTopicListData(id, currentPage);
    if (result?.data?.data?.length > 0) {
      setVideoData(result?.data?.data);
      setTotal(result?.data?.total);
      setTopicName(result?.data?.data[0]?.topic);
      setLastPage(result?.data?.last_page);
    }
  };

  const loadMoreData = async () => {
    const result = await getTopicListData(id, currentPage + 1);
    if (result?.data?.data?.length > 0) {
      const newData = [...videoData, ...result?.data?.data];
      setVideoData(newData);
      setCurrentPage(currentPage + 1);
    }
  };

  function videoSort() {
    const sorted = [...videoData].sort((a, b) => b.view_count - a.view_count);
    setVideoData(sorted);
  }

  function videoSortAlpha() {
    const sorted = [...videoData].sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setVideoData(sorted);
    // setActiveItem("title");
  }

  return (
    <>
      <Header />
      <div className="container">
        <div className="category-row mt-5 mb-5">
          <div className="row justify-content-between">
            <div className="col-6">
              {topicName && (
                <h2>
                  {topicName}
                  <span>[showing total {total} results]</span>
                </h2>
              )}
            </div>
            <div className="col-auto text-right">
              <form noValidate="" className="">
                <div className="border-border">
                  <div className="dropdown">
                    <a
                      target="_self"
                      onClick={() => {
                        if (sort === "none") {
                          setSort("block");
                        } else {
                          setSort("none");
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Sort
                    </a>
                    <div
                      id="sortSearch"
                      className="dropdown-options"
                      style={{ display: sort }}
                    >
                      <a
                        target="_self"
                        onClick={() => {
                          videoSort();
                          setSort("none");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Sort (by views)
                      </a>
                      <a
                        target="_self"
                        onClick={() => {
                          videoSortAlpha();
                          setSort("none");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Sort (A-Z title)
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            {videoData &&
              videoData?.map((video, i) => (
                <div className="col-md-3 mt-4" key={video.id}>
                  <div className="rel_position rel2">
                    <AppLink to={"/ViewCourse/" + video.id} onClick={() => {}}>
                      <div
                        className="thumbnail-box"
                        style={{ border: "1px solid white" }}
                      >
                        <img
                          style={{ objectFit: "fill" }}
                          src={video.thumbnail}
                          height="250px"
                          width="100%"
                          alt="product-img"
                        />
                      </div>
                      <div className="thumbnail-title">
                        {video.title}
                        <span class="thumb_title">{video.title}</span>
                      </div>
                    </AppLink>
                    <div className="row justify-content-between">
                      <div className="sub-title-1">
                        {video.producer}
                        <span className="produ_hover prod_2">
                          {video.producer}
                        </span>
                      </div>
                      <div className="sub-title-2">
                        {video.view_count > 0 ? video.view_count : 0} Views
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {videoData && videoData?.length > 0 && currentPage < lastPage && (
              <div className="text-center mt-5">
                <button
                  onClick={loadMoreData}
                  className="btn-primary btn-blue ng-star-inserted"
                >
                  Show more
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Topic;
