import { createSlice } from '@reduxjs/toolkit';

const showModalSlice = createSlice({
    name: 'showModal',
    initialState: false,
    reducers: {
        isShow: (state, action) => {
            const name = localStorage.getItem('guestName')
            state = name?.length > 0 ? false : true;
            return state;
        }
    },
});

export const { isShow } = showModalSlice.actions;
export default showModalSlice.reducer;
