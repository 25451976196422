import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { courseDetails, saveKeyPoints } from "../../../api/request";
import Loader from "../../../common/Loader";
import { showSuccessMessage, showWarning } from "../../../common/Toast";

const imageOption = [
  {
    id: "0",
    value: "none",
  },
  {
    id: "1",
    value: "Square Image Right and Text Left",
  },
  {
    id: "2",
    value: "Square Image Left and Text Right",
  },
  {
    id: "3",
    value: "Centred Text and Landscape Image",
  },
  {
    id: "4",
    value: "Text on Left and Portrait Image on Right",
  },
  {
    id: "5",
    value: "Text on Right and Portrait Image on Left",
  },
];
export default function KeyPoint() {
  const [thumbnail, setThumbnail] = useState("");
  const [imgLink, setImgLink] = useState("");
  const [keyPointData, setKeyPointData] = useState([]);
  const [index, setIndex] = useState(0);
  const [page_id, setPage_id] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getKeyPointsList();
  }, []);

  const getKeyPointsList = async () => {
    setIsLoad(true);
    const id = localStorage.getItem("courseId");
    const result = await courseDetails(id, "page_keypoint_section");
    // console.log("keypoint", result);
    if (result) {
      setKeyPointData(result);
      setValue("section_description", result[0]?.section_description);
      setValue("title", result[0]?.title);
      setValue("description", result[0]?.description);
      setValue("image_position", result[0]?.image_position);
      setImgLink(result[0]?.thumbnail);
      setPage_id(result[0]?.id);
      setIsLoad(false);
    } else {
      setIsLoad(false);
    }
  };

  const onFileChange = (event) => {
    setThumbnail(event.target.files[0]);
  };

  const handleImagePosition = (val) => {
    setValue("image_position", val.target.value);
  };

  const submitForm = async (data) => {
    const course_id = localStorage.getItem("courseId");
    let payload = {
      ...data,
      course_id,
      thumbnail,
      page_id,
    };
    console.log(payload);
    const result = await saveKeyPoints(course_id, payload);
    console.log(result);
    if (result?.status) {
      showSuccessMessage(result?.message);
      getKeyPointsList();
      // window.location.reload()
    }
  };

  const handlePageChange = (flag) => {
    let ind = flag === "next" ? index + 1 : index - 1;
    setIndex(ind);
    setValue("section_description", keyPointData[ind]?.section_description);
    setValue("title", keyPointData[ind]?.title);
    setValue("description", keyPointData[ind]?.description);
    setImgLink(keyPointData[ind]?.thumbnail);
    setPage_id(keyPointData[ind]?.id);
  };

  const addNewPage = () => {
    if (keyPointData.length < 12) {
      setPage_id("");
      setImgLink("");
      setKeyPointData([
        ...keyPointData,
        { section_description: "", title: "", description: "", thumbnail: "" },
      ]);
      setIndex(keyPointData.length);
      reset();
    } else {
      showWarning("Page limit exceed!");
    }
  };

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-10 offset-md-1">
          <h4 className="build_course_heading">ADD/EDIT KEY POINTS PAGES</h4>
          {isLoad ? (
            <Loader />
          ) : (
            <form
              className="buildCourse_form"
              id="form"
              onSubmit={handleSubmit(submitForm)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <button
                  type="button"
                  disabled={index === 0}
                  onClick={() => {
                    if (index > 0) {
                      setIndex(index - 1);
                      handlePageChange("prev");
                    }
                  }}
                >
                  Prev
                </button>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#FFF",
                  }}
                >
                  {index + 1}/12
                </h4>
                <button
                  type="button"
                  disabled={index === keyPointData?.length - 1}
                  onClick={() => {
                    if (index < keyPointData.length - 1) {
                      setIndex(index + 1);
                      handlePageChange("next");
                    }
                  }}
                >
                  Next
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Edit or use default Section text (max 25 words):</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control w-100"
                    defaultValue="The Key Points will give you the essential features of 
                    this topic, in the minimum number of text and pages."
                    {...register("section_description", {
                      required: {
                        value: true,
                        message: "Section description is required",
                      },
                    })}
                  />
                  <span style={{ color: "red" }}>
                    {errors?.section_description?.message}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Name/heading of page (max 10 words):*</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control w-100"
                    defaultValue="Key Points"
                    {...register("title", {
                      required: { value: true, message: "Title is required" },
                    })}
                  />
                  <span style={{ color: "red" }}>{errors?.title?.message}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Text of Key Points page (max 60 words):*</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control w-100"
                    defaultValue="The introduction states the aim of this course, what you will learn, and how you can apply your learning immediately in your work."
                    {...register("description", {
                      required: {
                        value: true,
                        message: "Description is required",
                      },
                    })}
                  />
                  <span style={{ color: "red" }}>
                    {errors?.description?.message}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Select Image:*</p>
                <div className="list-image ms-5">
                  <input
                    type="file"
                    // required={true}
                    className="form-control add_img_btn w-100"
                    placeholder="Add image from file"
                    onChange={onFileChange}
                  />
                  {imgLink && (
                    <a
                      href={imgLink}
                      target="_blank"
                      style={{ color: "#fff" }}
                      rel="noopener noreferrer"
                    >
                      View Image
                    </a>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Image position:*</p>
                <div className="w-100 ms-5">
                  <Controller
                    name="image_position"
                    className="form-control w-100"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, ...field } }) => (
                      <select {...field} onChange={handleImagePosition}>
                        <option id="" value="">
                          Select image position from dropdown list
                        </option>
                        {imageOption &&
                          imageOption.map((option) => (
                            <option id={option.id} key={option.id}>
                              {option.value}
                            </option>
                          ))}
                      </select>
                    )}
                  />
                  <span style={{ color: "red", fontSize: 14 }}>
                    {errors?.image_position?.message}
                  </span>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={addNewPage}
                  className="green-btn green-btn2"
                >
                  Add New
                </button>
                <div className="form-outline w-75 ms-auto edit_course_btns mt-4">
                  <button
                    type="submit"
                    disabled={!isDirty && !isValid}
                    className="green-btn"
                  >
                    Save
                  </button>
                  <button type="button" className="green-btn green-btn2">
                    View Course So Far
                  </button>
                  <button
                    type="button"
                    // onClick={handleDelete}
                    className="green-btn delete-btn"
                  >
                    Next Page
                  </button>
                  <button type="button" className="green-btn back-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
