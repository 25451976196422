import axios from "axios";

let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const instance = axios.create({
  baseURL: "https://api.snippizz.com/api/",
  headers: headers,
  "Access-Control-Allow-Origin": "*",
});

export async function getRequest(requestUrl) {
  try {
    const response = await instance.get(requestUrl);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function postRequest(requestUrl, data) {
  try {
    const response = await instance.post(requestUrl, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function getRequestToken(requestUrl) {
  try {
    const token = localStorage.getItem("token");
    const response = await instance.get(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function postRequestToken(requestUrl, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await instance.post(requestUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function deleteRequestToken(requestUrl, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await instance.delete(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
