import {
  postRequest,
  postRequestToken,
  getRequest,
  getRequestToken,
  deleteRequestToken,
} from "./api";

import * as endPoints from "./endpoints";

export const UserLogin = async (data) => {
  try {
    let result = await postRequest(endPoints.Login, data);

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const SocialLogin = async (data) => {
  try {
    let result = await postRequest(endPoints.socialLogin, data);

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const UserRegistration = async (data) => {
  try {
    let result = await postRequest(endPoints.SignUp, data);

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const dashboardData = async () => {
  try {
    let result = await getRequest(endPoints.home);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCategories = async () => {
  try {
    let result = await getRequest(endPoints.categories);
    if (result) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTopicList = async (id) => {
  try {
    let result = await getRequestToken(`${endPoints.getTopic}/${id}`);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTopics = async (id) => {
  try {
    let result = await getRequest(`${endPoints.topic}/${id}`);
    if (result) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAuthorCourse = async (id) => {
  try {
    let result = await getRequest(`${endPoints.authorCourse}/${id}`);
    if (result) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getProducerData = async (id, page) => {
  try {
    let result = await getRequest(
      `${endPoints.producerData}/${id}?page=${page}`
    );
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTopCourses = async () => {
  try {
    let result = await getRequest(`${endPoints.topCourses}`);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkVideoExists = async (id) => {
  try {
    const result = await postRequest(`${endPoints.videoExists}`, id);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getTopicListData = async (id, page) => {
  try {
    let result = await postRequest(
      `${endPoints.getTopicData}/${id}?page=${page}`
    );
    if (result?.data?.data?.length > 0) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSearchResult = async (keyword, page) => {
  try {
    let result = await getRequest(
      `${endPoints.searchCourses}/${keyword}?page=${page}`
    );
    if (result?.data?.length > 0) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};
export const reportVideo = async (id) => {
  try {
    let result = await getRequest(`${endPoints.videoReport}/${id}`);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const uploadTemplate = async (data) => {
  try {
    let result = await postRequestToken(endPoints.template, data);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const createCourse = async (data) => {
  try {
    let result = await postRequestToken(endPoints.createCourse, data);
    if (result?.status) {
      return result;
    }
    return result;
  } catch (error) {
    console.log("request error", error);
    // return error;
  }
};

export const increaseCourseCount = async (id) => {
  try {
    let result = await getRequest(`${endPoints.videoCount}/${id}`);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getMyCourse = async (page) => {
  try {
    let result = await getRequestToken(`${endPoints.myCourse}?page=${page}`);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const courseDetails = async (course_id) => {
  try {
    let result = await getRequestToken(
      `${endPoints.courseDetails}/${course_id}`
    );
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCourse = async (data) => {
  try {
    let result = await postRequestToken(endPoints.updateCourse, data);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteCourse = async (id) => {
  try {
    let result = await deleteRequestToken(`${endPoints.deleteCourse}/${id}`);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveIntroduction = async (id, data) => {
  try {
    let result = await postRequestToken(
      `${endPoints.saveIntroduction}/${id}`,
      data
    );
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveKeyPoints = async (id, data) => {
  try {
    let result = await postRequestToken(
      `${endPoints.saveKeyPoints}/${id}`,
      data
    );
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveNotesPage = async (id, data) => {
  try {
    let result = await postRequestToken(
      `${endPoints.saveNotesPage}/${id}`,
      data
    );
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveQuizPage = async (id, data) => {
  try {
    let result = await postRequestToken(`${endPoints.saveQuiz}/${id}`, data);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addTextAndImage = async (id, data) => {
  try {
    let result = await postRequestToken(`${endPoints.imageAndText}${id}`, data);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteAddTextAndImage = async (id) => {
  try {
    let result = await deleteRequestToken(
      `${endPoints.deleteImageAndText}${id}`
    );
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addVideo = async (id, data) => {
  try {
    let result = await postRequestToken(`${endPoints.saveVideo}/${id}`, data);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteVideo = async (id) => {
  try {
    let result = await deleteRequestToken(`${endPoints.deleteVideo}${id}`);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addEmbeds = async (id, data) => {
  try {
    let result = await postRequestToken(`${endPoints.saveEmbeds}/${id}`, data);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getGallery = async () => {
  try {
    let result = await getRequestToken(endPoints.getGalleryList);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllCategories = async () => {
  try {
    let result = await getRequestToken(endPoints.categoryList);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getViewCourse = async () => {
  try {
    let result = await getRequestToken(endPoints.viewCourse);
    if (result?.status) {
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};
