import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import YouTube from "react-youtube";

import { courseDetails, reportVideo } from "../../api/request";
import { Copy, Report, Share, View } from "../../assets/images";
import { Loader, CopyModal, AppButton } from "../../common";
import { showInformation } from "../../common/Toast";
import Header from "../../components/Header/Header";
import { useLocation } from "react-router-dom";

const ViewCourse = () => {
  const { id } = useParams();
  const location = useLocation();
  const values = location.state;
  const imageUrl = URL.createObjectURL(values.thumbnail);

  const Name = localStorage.getItem("guestName");

  localStorage.setItem("course_id", id);
  const navigate = useNavigate();

  const [isLoad, setIsLoad] = useState(false);
  const [courseList, setCourseList] = useState({});
  const [toggleView, setToggleView] = useState(true);
  const [showIntro, setShowIntro] = useState("");
  const [clipboardText, setClipboardText] = useState("");

  useEffect(() => {
    getCourseDetails();
  }, []);

  const getCourseDetails = async () => {
    if (id !== null) {
      setIsLoad(true);
      const result = await courseDetails(id);
      if (result?.status) {
        setCourseList(result?.data);
      }
      setIsLoad(false);
    }
  };

  function getYouTubeId(url) {
    let videoId = "";
    const regex =
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:$|&|\?|#)/;
    const match = url?.match(regex);
    if (match && match[1]) {
      videoId = match[1];
    }

    return videoId;
  }

  const handleCopyText = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Error copying text: ", err);
    }
    closeModal();
  };

  const closeModal = () => {
    setShowIntro("");
  };

  const handleVideoReport = async () => {
    const result = await reportVideo(courseList?.id);
    console.log("report", result);
  };

  return (
    <div>
      <Header />
      {showIntro === "intro" ? (
        <CopyModal
          title="Course Intro"
          content={courseList && values?.intro}
          handleClose={closeModal}
        />
      ) : showIntro === "embed" ? (
        <CopyModal
          title={"Embed Code"}
          content={`<iframe width="420" height="345" src=${window?.location?.href} title="Build-a-course"></iframe>`}
          handleCopy={() => {
            let text = `<iframe
                width="420"
                height="345"
                src=${window?.location?.href}
                title="Build-a-course"
              ></iframe>`;
            handleCopyText(text);
          }}
          handleClose={closeModal}
        />
      ) : showIntro === "share" ? (
        <CopyModal
          title="Share Course"
          content={window?.location?.href}
          handleCopy={() => {
            handleCopyText(window?.location?.href);
          }}
          handleClose={closeModal}
        />
      ) : showIntro === "notes" ? (
        <CopyModal
          title="Add Your Notes"
          onChange={(e) => setClipboardText(e.target.value)}
          content={clipboardText}
          handleCopy={() => {
            handleCopyText(clipboardText);
          }}
          handleClose={closeModal}
        />
      ) : showIntro === "report" ? (
        <CopyModal
          title="Report Missing Video"
          content={
            'A "Report Missing Video" message has now been sent to Build a course team. Thank you!'
          }
          handleClose={closeModal}
        />
      ) : null}
      <section className="middle-part video-page">
        {isLoad ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="min-category-page zoomInDown">
                  <h2>{courseList && values?.title}</h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4">
                <div className="pe-xxl-0 pe-xl-4 pe-0 h-100">
                  <div className="cat-box-mian bounceInLeft">
                    <div className="cat-box-block">
                      <div className="cat-box-block-inner">
                        <h3>Category:</h3>
                        <div className="sub-block">
                          {courseList && values?.category}
                        </div>
                      </div>
                    </div>
                    <div className="cat-box-block">
                      <div className="cat-box-block-inner">
                        <h3>Topic:</h3>
                        <div className="sub-block">
                          {courseList && values?.topic}
                        </div>
                      </div>
                    </div>
                    <div className="cat-box-block">
                      <div className="cat-box-block-inner">
                        <h3>Course added by:</h3>
                        <div className="sub-block">{courseList && Name}</div>
                      </div>
                    </div>
                    <div className="cat-box-block mb-0">
                      <div className="cat-box-block-inner description-tooltip">
                        <h3>Video made by:</h3>
                        <div className="sub-block">
                          {courseList && values?.producer}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-4">
                <div className="youtube-videos position-relative h-auto">
                  {toggleView ? (
                    <img
                      width="100%"
                      style={{ height: "400px", objectFit: "contain" }}
                      alt="product-img"
                      src={courseList && imageUrl}
                    />
                  ) : (
                    <>
                      {courseList && courseList?.video_type === "media" ? (
                        <video
                          src={courseList?.video_url}
                          width="750"
                          height="500"
                          controls
                        ></video>
                      ) : (
                        <YouTube
                          style={{ height: "400px", objectFit: "contain" }}
                          videoId={getYouTubeId(courseList?.video_url)}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <AppButton
                    type="button"
                    className="viewdetail-btn"
                    onClick={() => setShowIntro("intro")}
                  >
                    Course Intro
                  </AppButton>
                  <AppButton
                    type="button"
                    className="viewdetail-btn"
                    onClick={() => setToggleView(!toggleView)}
                  >
                    {toggleView ? "Play Video" : "View Image"}
                  </AppButton>
                  <AppButton
                    type="button"
                    className="viewdetail-btn"
                    onClick={async () => {
                      // try {
                      //   const text = await navigator.clipboard.readText();
                      //   setClipboardText(text);
                      //   console.log("Text from clipboard: ", text);
                      // } catch (err) {
                      //   console.error("Error reading clipboard: ", err);
                      // }
                      setShowIntro("notes");
                    }}
                  >
                    Add your notes
                  </AppButton>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4">
                <div className="pe-xxl-0 pe-xl-4 pe-0 h-100">
                  <div className="cat-box-mian bounceInRight">
                    <div className="cat-box-block">
                      <div
                        className="cat-box-block-inner box-right"
                        onClick={() =>
                          navigate(
                            "/subCategoryList/" + courseList?.category_id
                          )
                        }
                      >
                        <img _ngcontent-vug-c36="" src={View} alt=""></img>
                        <h3>View other courses in this topic</h3>
                      </div>
                    </div>
                    <div className="cat-box-block">
                      <div
                        className="cat-box-block-inner box-right"
                        onClick={() => setShowIntro("embed")}
                      >
                        <img _ngcontent-vug-c36="" src={Copy} alt=""></img>
                        <h3>Copy embed code</h3>
                      </div>
                    </div>
                    <div className="cat-box-block">
                      <div
                        className="cat-box-block-inner box-right"
                        onClick={() => setShowIntro("share")}
                      >
                        <img _ngcontent-vug-c36="" src={Share} alt=""></img>
                        <h3>Share with others</h3>
                      </div>
                    </div>
                    <div className="cat-box-block">
                      <div
                        className="cat-box-block-inner box-right"
                        onClick={() => {
                          handleVideoReport();
                          setShowIntro("report");
                        }}
                      >
                        <img _ngcontent-vug-c36="" src={Report} alt=""></img>
                        <h3>Report video issue</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default ViewCourse;
