/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";

import { Footer } from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { getName } from "../../redux/slices/guestNameSlice";
import { deleteCourse, getMyCourse } from "../../api/request";
import { Next, Prev } from "../../assets/images";
import { AppButton, AppLink, Loader } from "../../common";
import { useNavigate } from "react-router-dom";

const MyCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const name = useSelector((state) => state.guestName);
  const [videoData, setVideoData] = useState([]);
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [aboutContent, setAboutContent] = useState("");
  const [sort, setSort] = useState("none");
  const [user_ID, setuser_Id] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [total, setTotal] = useState("");

  useEffect(() => {
    dispatch(getName());
    fetchMyCourse();
  }, []);

  const fetchMyCourse = async () => {
    let authBio = localStorage.getItem("bio");
    setAboutContent(authBio);
    const ID = JSON.parse(localStorage.getItem("userDetails"));
    setuser_Id(ID?.id);
    setIsLoading(true);
    const result = await getMyCourse(currentPage);
    if (result?.status) {
      setVideoData(result?.data?.data);
      setLastPage(result?.data?.last_page);
      setTotal(result?.data?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const carouselSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function videoSort() {
    const sorted = [...videoData].sort((a, b) => b.view_count - a.view_count);
    setVideoData(sorted);
  }

  function videoSortAlpha() {
    const sorted = [...videoData].sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setVideoData(sorted);
  }

  const deleteCourseItem = async () => {
    const result = await deleteCourse(courseId);
    if (result?.status) {
      setDeleteModal(false);
      fetchMyCourse();
      // console.log(result);
    }
  };

  function SampleNextArrow(props) {
    const { onClick, className } = props;
    return <img alt="a" className={className} src={Prev} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { onClick, className } = props;
    return <img alt="a" className={className} src={Next} onClick={onClick} />;
  }

  const loadMoreData = async () => {
    const result = await getMyCourse(currentPage + 1);
    if (result?.status) {
      const newData = [...videoData, ...result?.data?.data];
      setVideoData(newData);
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <Header />
      {modalShow && (
        <div
          className="raund-modal raund_m1"
          id="raund-user"
          style={{ zIndex: 999 }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body modal-inner-box  ">
                <div className="model-content success_modal">
                  {aboutContent && <h3>{aboutContent}</h3>}

                  <div className="form-outline adddetail_btns">
                    <AppButton
                      type="button"
                      className="green-btn"
                      onClick={() => setModalShow(false)}
                    >
                      Close
                    </AppButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <div
          className="raund-modal raund_m1"
          id="raund-user"
          style={{ zIndex: 999 }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body modal-inner-box  ">
                <div className="model-content">
                  <h3>Are you sure?</h3>

                  <div className="ineer-popup-close-btn submit-popup">
                    <AppButton
                      data-bs-dismiss="modal"
                      className="green-btn"
                      onClick={deleteCourseItem}
                    >
                      Yes
                    </AppButton>
                    <AppButton
                      data-bs-dismiss="modal"
                      className="green-btn delete-btn"
                      onClick={() => {
                        setDeleteModal(false);
                      }}
                    >
                      Cancel
                    </AppButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section className="middle-part video-list video-contain">
            <div className="container">
              <div className="row justify-content-between"></div>
              <div className="col-auto">
                <h2 className="category-topic m-0">
                  My Micro-Courses: {name} {""} [showing total {total} results ]
                  {/* {aboutContent?.length > 0 && (
                    <span
                      style={{ fontSize: 22, cursor: "pointer" }}
                      onClick={() => setModalShow(true)}
                    >
                      ( click for details)
                    </span>
                  )} */}
                </h2>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="btop_filer">
                    {videoData && videoData.length > 0 && (
                      <form noValidate="" className="">
                        <div className="border-border">
                          <div className="dropdown">
                            <a
                              target="_self"
                              onClick={() => {
                                if (sort === "none") {
                                  setSort("block");
                                } else {
                                  setSort("none");
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Sort
                            </a>
                            <div
                              id="sortSearch"
                              className="dropdown-options"
                              style={{ display: sort }}
                            >
                              <a
                                target="_self"
                                onClick={() => {
                                  videoSort();
                                  setSort("none");
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Sort (by views)
                              </a>
                              <a
                                target="_self"
                                onClick={() => {
                                  videoSortAlpha();
                                  setSort("none");
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Sort (A-Z title)
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}

                    {videoData?.length > 0 &&
                      (show ? (
                        <div
                          className="view-btn"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          View All ({total})
                        </div>
                      ) : null)}
                  </div>
                </div>
              </div>

              {videoData && show ? (
                <div className="row">
                  <div
                    className="col-md-12 product-video-box"
                    id="galleries-list"
                  >
                    <div className="col-md-12">
                      <div className="wrapper">
                        <Slider {...carouselSettings} initialSlide={0}>
                          {videoData &&
                            videoData.map((video) => {
                              const {
                                id,
                                thumbnail,
                                title,
                                producer,
                                view_count,
                              } = video;
                              return (
                                <div className="px-2 rel_position" key={id}>
                                  <AppLink
                                    to={`/ViewCourse/${id}`}
                                    onClick={() => {}}
                                  >
                                    <div className="thumbnail-box ">
                                      <img
                                        style={{ objectFit: "fill" }}
                                        src={thumbnail}
                                        height="250px"
                                        width="80%"
                                        alt="product-img"
                                      />
                                    </div>
                                    <div className="thumbnail-title">
                                      {title}
                                      <span className="thumb_title">
                                        {title}
                                      </span>
                                    </div>
                                  </AppLink>
                                  {/* {user_ID === user_id ? ( */}
                                  <Dropdown className="top-dropBtn">
                                    <Dropdown.Toggle
                                      variant="secondary"
                                      id="dropdown-basic"
                                      className="drop-btn"
                                    >
                                      <img
                                        alt="img"
                                        src={require("../../assets/images/dots.png")}
                                        className="imgDots"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          navigate(`/editCourse/${id}`)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#/action-2"
                                        onClick={() => {
                                          setCourseId(id);
                                          setDeleteModal(true);
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  {/* ) : null} */}
                                  <div className="row justify-content-between">
                                    <div className="col-auto">
                                      <div className="sub-title-1">
                                        {producer}
                                        <span className="produ_hover prod_2">
                                          {producer}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      <div className="sub-title-2">
                                        {view_count > 0 ? view_count : 0} Views
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-4">
                  {videoData &&
                    videoData?.map((video, i) => (
                      <div className="col-md-3 mt-4" key={video.id}>
                        <div className="rel_position rel2">
                          <AppLink
                            to={"/ViewCourse/" + video.id}
                            onClick={() => {}}
                          >
                            <div
                              className="thumbnail-box"
                              style={{ border: "1px solid white" }}
                            >
                              <img
                                style={{ objectFit: "fill" }}
                                src={video.thumbnail}
                                height="250px"
                                width="100%"
                                alt="product-img"
                              />
                            </div>
                            <div className="thumbnail-title">
                              {video.title}
                              <span className="thumb_title">{video.title}</span>
                            </div>
                          </AppLink>
                          <Dropdown className="top-dropBtn">
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                              className="drop-btn"
                            >
                              <img
                                alt="img"
                                src={require("../../assets/images/dots.png")}
                                className="imgDots"
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#/action-1"
                                onClick={() =>
                                  navigate(`/editCourse/${video?.id}`)
                                }
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#/action-2"
                                onClick={() => {
                                  setCourseId(video?.id);
                                  setDeleteModal(true);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="row justify-content-between">
                            <div className="sub-title-1">
                              {video.producer}
                              <span className="produ_hover prod_2">
                                {video.producer}
                              </span>
                            </div>
                            <div className="sub-title-2">
                              {video.view_count > 0 ? video.view_count : 0}{" "}
                              Views
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {videoData &&
                    videoData?.length > 0 &&
                    currentPage < lastPage && (
                      <div className="text-center mt-5">
                        <button
                          onClick={loadMoreData}
                          className="btn-primary btn-blue ng-star-inserted"
                        >
                          Show more
                        </button>
                      </div>
                    )}
                </div>
              )}
              {videoData?.length === 0 && (
                <div className="col-md-12">
                  <h3 className="error-msg">No matching records found</h3>
                </div>
              )}
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default MyCourse;
