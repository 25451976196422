/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { getTopics, deleteCourse } from "../../api/request";
import Loader from "../../common/Loader";
import { AppLink, AppButton } from "../../common";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { Next, Prev } from "../../assets/images";

export default function SubCategoryList() {
  const { id, topicId } = useParams();
  const navigate = useNavigate();

  const [heading, setHeading] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [topicData, setTopic] = useState([]);
  const [show, setShow] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [user_ID, setuser_Id] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [sort, setSort] = useState({
    index: -1,
    sort: "none",
  });

  useEffect(() => {
    getTopicsList();
  }, []);

  const getTopicsList = async () => {
    const ID = JSON.parse(localStorage.getItem("userDetails"));
    setuser_Id(ID?.id);
    setIsLoad(true);
    const result = await getTopics(id);
    if (result) {
      console.log({ id, topicId });
      setHeading(result?.category?.name);
      if (topicId != "all") {
        const filteredTopics = result?.topics?.filter(
          (ele) => ele.id == topicId
        );
        setTopicList(filteredTopics);
      } else {
        setTopicList(result?.topics);
      }

      var resultData = [];
      result?.topics.forEach(function (i) {
        resultData.push(...i.courses);
      });
      setTopic(topicData);
      setIsLoad(false);
    }
  };

  const deleteCourseItem = async () => {
    const result = await deleteCourse(courseId);
    if (result?.status) {
      setDeleteModal(false);
      getTopicsList();
      console.log(result);
    }
  };

  const onChanges = (event, index) => {
    if (event === "views") {
      videoSort(index);
    } else if (event === "title") {
      videoSortAlpha(index);
    }
  };

  function videoSort(index) {
    let currentTopics = [...topicList];
    currentTopics[index]?.courses?.sort((a, b) => b.view_count - a.view_count);
    setTopicList(currentTopics);
    setActiveItem("views");
  }

  function videoSortAlpha(index) {
    let currentTopics = [...topicList];
    currentTopics[index]?.courses?.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    setTopicList(currentTopics);
    setActiveItem("title");
  }

  function SampleNextArrow(props) {
    const { onClick, className } = props;
    return <img alt="a" className={className} src={Prev} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { onClick, className } = props;
    return <img alt="a" className={className} src={Next} onClick={onClick} />;
  }

  const carouselSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      {deleteModal && (
        <div
          className="raund-modal raund_m1"
          id="raund-user"
          style={{ zIndex: 999 }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body modal-inner-box  ">
                <div className="model-content">
                  <h3>Are you sure?</h3>

                  <div className="ineer-popup-close-btn submit-popup">
                    <AppButton
                      data-bs-dismiss="modal"
                      className="green-btn"
                      onClick={deleteCourseItem}
                    >
                      Yes
                    </AppButton>
                    <AppButton
                      data-bs-dismiss="modal"
                      className="green-btn delete-btn"
                      onClick={() => {
                        setDeleteModal(false);
                      }}
                    >
                      Cancel
                    </AppButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoad ? (
        <Loader />
      ) : (
        <section className="middle-part video-list subCatePage">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12">
                <h2 className="heading_main">{heading && heading}</h2>
              </div>
            </div>

            <div className="row">
              {topicList &&
                topicList.map(
                  (topic, index) =>
                    topic?.courses?.length > 0 && (
                      <>
                        <div key={topic?.id} className="main_cate row">
                          <div className="col-md-6 ">
                            <h2 className="category-topic m-0">{topic.name}</h2>
                          </div>
                          <div className="col-md-6">
                            <div className="ms-2">
                              <div className="col-md-12">
                                <div className="btop_filer">
                                  {topic?.courses &&
                                    topic?.courses?.length > 0 && (
                                      <form noValidate="" className="">
                                        <div className="border-border">
                                          <div className="dropdown">
                                            <a
                                              target="_self"
                                              onClick={() => {
                                                if (
                                                  sort.index === index ||
                                                  sort.sort === "none"
                                                ) {
                                                  setSort((prevSort) => ({
                                                    ...prevSort,
                                                    index,
                                                    sort: "block",
                                                  }));
                                                } else {
                                                  setSort((prevSort) => ({
                                                    ...prevSort,
                                                    index,
                                                    sort: "none",
                                                  }));
                                                }
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              Sort
                                            </a>
                                            <div
                                              id="sortSearch"
                                              className="dropdown-options"
                                              style={{
                                                display:
                                                  sort.index === index
                                                    ? sort.sort
                                                    : "none",
                                              }}
                                            >
                                              <a
                                                target="_self"
                                                onClick={() => {
                                                  videoSort(index);
                                                  setSort((prevSort) => ({
                                                    ...prevSort,
                                                    index,
                                                    sort: "none",
                                                  }));
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                Sort (by views)
                                              </a>
                                              <a
                                                target="_self"
                                                onClick={() => {
                                                  videoSortAlpha(index);
                                                  setSort((prevSort) => ({
                                                    ...prevSort,
                                                    index,
                                                    sort: "none",
                                                  }));
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                Sort (A-Z title)
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    )}
                                  {topic?.courses?.length > 0 &&
                                    (show ? (
                                      <div
                                        className="view-btn"
                                        onClick={() => {
                                          navigate(`/topic/${topic?.id}`);
                                        }}
                                      >
                                        View All ({topic?.total_course})
                                      </div>
                                    ) : null)}
                                </div>
                              </div>
                            </div>
                          </div>
                          {topic?.courses && show ? (
                            <Slider {...carouselSettings} initialSlide={0}>
                              {topic?.courses?.length > 0 ? (
                                topic?.courses?.map((course) => (
                                  <div
                                    key={course?.id}
                                    className="gallery_lists px-1 position-relative"
                                  >
                                    <AppLink
                                      to={"/viewCourse/" + course.id}
                                      onClick={() => {}}
                                    >
                                      <div className="gal-img">
                                        <img
                                          src={course?.thumbnail}
                                          alt="product_img"
                                        />
                                      </div>

                                      <div className="thumbnail-title">
                                        {course?.title}
                                        <span class="thumb_title">
                                          {course?.title}
                                        </span>
                                      </div>
                                    </AppLink>

                                    <div className="row justify-content-between">
                                      <div className="col-auto">
                                        <div className="sub-title-1">
                                          {course?.producer}
                                          <span className="produ_hover prod_2">
                                            {course?.producer}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="sub-title-2">
                                          {course?.view_count > 0
                                            ? course?.view_count
                                            : 0}{" "}
                                          Views
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="col-md-12">
                                  <h3 className="error-msg">
                                    No records found
                                  </h3>
                                </div>
                              )}
                            </Slider>
                          ) : (
                            <div className="row mt-4">
                              {topic?.courses &&
                                topic?.courses?.map((video, i) => (
                                  <div
                                    className="col-md-3 mt-4 position-relative"
                                    key={video.id}
                                  >
                                    <AppLink
                                      to={"/ViewCourse/" + video.id}
                                      onClick={() => {}}
                                    >
                                      <div
                                        className="thumbnail-box"
                                        style={{ border: "1px solid white" }}
                                      >
                                        <img
                                          style={{ objectFit: "fill" }}
                                          src={video.thumbnail}
                                          height="250px"
                                          width="100%"
                                          alt="product-img"
                                        />
                                      </div>
                                      <div className="thumbnail-title">
                                        {video.title}
                                        <span className="produ_hover prod_2">
                                          {video.title}
                                        </span>
                                      </div>
                                    </AppLink>
                                    {user_ID === video?.user_id ? (
                                      <Dropdown className="top-dropBtn">
                                        <Dropdown.Toggle
                                          variant="secondary"
                                          id="dropdown-basic"
                                          className="drop-btn"
                                        >
                                          <img
                                            alt="img"
                                            src={require("../../assets/images/dots.png")}
                                            className="imgDots"
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            href="#/action-1"
                                            onClick={() =>
                                              navigate(`/basicDetails`, {
                                                state: {
                                                  courseData: video?.id,
                                                },
                                              })
                                            }
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#/action-2"
                                            onClick={() => {
                                              setCourseId(video?.id);
                                              setDeleteModal(true);
                                            }}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : null}
                                    <div className="row justify-content-between">
                                      <div className="col-auto">
                                        <div className="sub-title-1">
                                          {video?.producer}
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="sub-title-2">
                                          {video?.view_count > 0
                                            ? video?.view_count
                                            : 0}{" "}
                                          Views
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {topic?.courses?.length === 0 && (
                            <div className="col-md-12">
                              <h3 className="error-msg">
                                No matching records found
                              </h3>
                            </div>
                          )}
                        </div>
                      </>
                    )
                )}
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
}
