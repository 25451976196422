import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addVideo, courseDetails, deleteVideo } from "../../../api/request";
import { showSuccessMessage } from "../../../common/Toast";

export default function Videos() {
  const [video_type, setVideo_type] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getVideoList();
  }, []);

  const getVideoList = async () => {
    let id = localStorage.getItem("courseId");
    let result = await courseDetails(id, "page_video_section");
    if (result) {
      setValue("section_description", result?.section_description);
      setValue("title", result?.title);
      if (result?.video_type === "youtube") {
        setVideo_type("youtube");
        setValue("video_url", result?.video_url);
      } else {
        setVideo_type("media");
        setVideoUrl(result?.video_url);
      }
    }
  };

  const submitForm = async (data) => {
    const course_id = localStorage.getItem("courseId");
    let payload = {
      ...data,
      course_id,
      video_type,
      section_title: "Video",
    };
    // console.log(payload);
    const result = await addVideo(course_id, payload);
    console.log(result);

    if (result?.status) {
      showSuccessMessage(result?.message);
      getVideoList();
    }
    window.scrollTo(0, 0);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setVideo_type("media");
    setValue("upload_video", selectedFile);

    // console.log(selectedFile);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <form
            className="buildCourse_form"
            id="form"
            onSubmit={handleSubmit(submitForm)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Edit or use default Section text:</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control w-100"
                  defaultValue="The introduction states the aim of this course, what you will learn, and how you can apply your learning immediately in your work."
                  {...register("section_description", {
                    required: {
                      value: true,
                      message: "Section description is required",
                    },
                  })}
                />
                <span style={{ color: "red" }}>
                  {errors?.section_description?.message}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Name/heading of page (max 10 words):*</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control w-100"
                  defaultValue="Video"
                  {...register("title", {
                    required: {
                      value: true,
                      message: "Title is required",
                    },
                  })}
                />
                <span style={{ color: "red" }}>{errors?.title?.message}</span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>
                {video_type === "youtube" ? "Video url:" : "Video from file :"}
              </p>
              {video_type === "youtube" ? (
                <div className="w-75 ms-5">
                  <input
                    type="text"
                    id="video_url"
                    className="form-control w-100"
                    placeholder="Add YouTube video url here"
                    {...register("video_url", {
                      required: {
                        value: true,
                        message: "Video url is required.",
                      },
                      pattern: {
                        value:
                          /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?(?:[\w=&-]+)?v=([\w-]+)|([\w-]+))(?:&(?:[\w=&-]+))?$/,
                        message: "Please add in correct format.",
                      },
                    })}
                  />
                  <span style={{ color: "red", fontSize: 14 }}>
                    {errors?.video_url?.message}
                  </span>
                </div>
              ) : (
                <div className="w-75 ms-5">
                  <input
                    type="file"
                    name="upload_video"
                    accept="video/*"
                    className="form-control w-100"
                    onChange={handleFileChange}
                    // {...register("upload_video")}
                  />
                  {videoUrl && (
                    <a
                      href={videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {videoUrl}
                    </a>
                  )}
                </div>
              )}
            </div>

            <div className="cor_sec">
              <button
                type="button"
                className="add_new_btn"
                onClick={() => {
                  if (video_type === "youtube") {
                    setVideo_type("media");
                    setValue("video_url", "");
                  } else {
                    setVideo_type("youtube");
                    setValue("upload_video", "");
                  }
                }}
              >
                Toggle Video Type
              </button>
            </div>

            <div className="form-outline edit_course_btns mt-4">
              <button
                type="submit"
                className="green-btn"
                disabled={!isDirty && !isValid}
              >
                Save
              </button>
              <button type="button" className="green-btn blue-btn">
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-4">
          <div className="course_notes">
            <h4>Notes for Course Creators:</h4>
            <ul>
              <li>
                To add a YouTube video to your course, enter the full url(not
                abbreviated ones).
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
