import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { courseDetails, saveQuizPage } from "../../../api/request";
import Loader from "../../../common/Loader";
import { showErrorMessage, showSuccessMessage } from "../../../common/Toast";

export default function Quizzes() {
  const [index, setIndex] = useState(0);
  const [page_id, setPage_id] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [keyPointData, setKeyPointData] = useState([]);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getQuizList();
  }, []);

  const getQuizList = async () => {
    const id = localStorage.getItem("courseId");
    const quizData = [{ quiz_question: "" }]; //await courseDetails(id, "page_quiz_section");
    const keyPoints = await courseDetails(id, "page_keypoint_section");
    console.log(quizData, keyPoints);
    if (keyPoints?.length === 0) {
      showErrorMessage("Without Key points Section Quiz Section not working.");
    } else {
      setKeyPointData(keyPoints);
      setQuizData(quizData);
      // setValue("section_title", quizData[0]?.section_title);
      // setValue("section_description", quizData[0]?.section_description);
      setValue(
        "quiz_question",
        quizData[0]?.quiz_question === ""
          ? keyPoints[0]?.description
          : quizData[0]?.quiz_question
      );
      // setValue("missing_word", quizData[0]?.missing_word);
      setPage_id(quizData[0]?.id);
    }
  };

  const submitForm = async (data) => {
    const course_id = localStorage.getItem("courseId");
    let payload = {
      ...data,
      course_id,
      page_id,
    };
    console.log(payload);
    // const result = await saveQuizPage(course_id, payload);
    // console.log(result);
    // if (result?.status) {
    //   showSuccessMessage(result?.message);
    //   getQuizList();
    // }
  };

  const handlePageChange = (flag) => {
    let ind = flag === "next" ? index + 1 : index - 1;
    setIndex(ind);
    setValue("section_description", quizData[ind]?.section_description);
    setValue("title", quizData[ind]?.title);
    setValue("description", quizData[ind]?.description);
    setPage_id(quizData[ind]?.id);
  };

  const addNewPage = () => {
    setPage_id("");
    setQuizData([
      ...quizData,
      { section_description: "", title: "", description: "", thumbnail: "" },
    ]);
    setIndex(quizData.length);
    reset();
  };

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-10 offset-md-1">
          <h4 className="build_course_heading">
            ADD/EDIT MISSING WORDS QUIZ PAGES
          </h4>
          {isLoad ? (
            <Loader />
          ) : (
            <form
              className="buildCourse_form"
              id="form"
              onSubmit={handleSubmit(submitForm)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <button
                  type="button"
                  disabled={index === 0}
                  onClick={() => {
                    if (index > 0) {
                      setIndex(index - 1);
                      handlePageChange("prev");
                    }
                  }}
                >
                  Prev
                </button>
                <h4
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#FFF",
                  }}
                >
                  {index + 1}
                </h4>
                <button
                  type="button"
                  disabled={index === quizData?.length - 1}
                  onClick={() => {
                    if (index < quizData.length - 1) {
                      setIndex(index + 1);
                      handlePageChange("next");
                    }
                  }}
                >
                  Next
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Edit or use default Section text (max 25 words):</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control w-100"
                    defaultValue="The Quiz will give you the chance to review the key 
                    points of this course, check what you’ve learnt, and give you some fun."
                    {...register("section_description", {
                      required: {
                        value: true,
                        message: "Section description is required",
                      },
                    })}
                  />
                  <span style={{ color: "red" }}>
                    {errors?.section_description?.message}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Name/heading of page (max 10 words):*</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control w-100"
                    defaultValue="Quiz"
                    {...register("section_title", {
                      required: { value: true, message: "Title is required" },
                    })}
                  />
                  <span style={{ color: "red" }}>{errors?.title?.message}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Edit Text of Key Points for quiz:*</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control w-100"
                    {...register("quiz_question", {
                      required: {
                        value: true,
                        message: "Quiz Question is required",
                      },
                    })}
                  />
                  <span style={{ color: "red" }}>
                    {errors?.quiz_question?.message}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p>Missing Word:*</p>
                <div className="w-75 ms-5">
                  <textarea
                    type="text"
                    rows={1}
                    className="form-control w-100"
                    {...register("missing_word", {
                      required: {
                        value: true,
                        message: "Missing Word is required",
                      },
                    })}
                  />
                  <span style={{ color: "red" }}>
                    {errors?.missing_word?.message}
                  </span>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={addNewPage}
                  className="green-btn green-btn2"
                >
                  Add New
                </button>
                <div className="form-outline w-75 ms-auto edit_course_btns mt-4">
                  <button
                    type="submit"
                    disabled={!isDirty && !isValid}
                    className="green-btn"
                  >
                    Save
                  </button>
                  <button type="button" className="green-btn green-btn2">
                    View Course So Far
                  </button>
                  <button
                    type="button"
                    // onClick={handleDelete}
                    className="green-btn delete-btn"
                  >
                    Next Page
                  </button>
                  <button type="button" className="green-btn back-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
