import React from "react";
import { Link } from "react-router-dom";

import Wave from "../assets/audio/click.wav";

const AppLink = ({ to, children, onClick, ...linkProps }) => {
  const audio = new Audio(Wave);

  const playSound = () => {
    const isPlay = localStorage.getItem("soundPropCourse");
    if (isPlay === "true") {
      audio.play();
    }
  };
  return (
    <Link
      to={to}
      onClick={() => {
        onClick();
        playSound();
      }}
      {...linkProps}
    >
      {children}
    </Link>
  );
};

export default AppLink;
