import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { courseDetails, saveNotesPage } from "../../../api/request";
import { showSuccessMessage } from "../../../common/Toast";

export default function NotesPage() {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getNotesList();
  }, []);

  const getNotesList = async () => {
    const id = localStorage.getItem("courseId");
    const result = await courseDetails(id, "page_notes_section");
    if (result) {
      setValue("title", result?.title);
      setValue("section_description", result?.section_description);
      setValue(
        "description",
        result?.description?.replace(/(<([^>]+)>)/gi, "")
      );
    }
  };

  const submitForm = async (data) => {
    const course_id = localStorage.getItem("courseId");
    let payload = {
      ...data,
      course_id,
      section_title: "Notes",
    };
    const result = await saveNotesPage(course_id, payload);
    if (result?.status) {
      showSuccessMessage(result?.message);
      getNotesList();
    }
  };

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-10 offset-md-1">
          <h4 className="build_course_heading">ADD/EDIT NOTES PAGE</h4>
          <form
            className="buildCourse_form"
            id="form"
            onSubmit={handleSubmit(submitForm)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Edit or use default Section text (max 25 words):</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  maxLength={25}
                  className="form-control w-100"
                  defaultValue="The Notes page enables you to jot down anything 
                    you want to remember from this course including 
                    how you will apply the learning."
                  {...register("section_description", {
                    required: {
                      value: true,
                      message: "Section description is required",
                    },
                  })}
                />
                <span style={{ color: "red" }}>
                  {errors?.section_description?.message}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Name/heading of page (max 10 words):*</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control w-100"
                  defaultValue="The introduction states the aim of this course, what you will learn, and how you can apply your learning immediately in your work."
                  {...register("title", {
                    required: { value: true, message: "Title is required" },
                  })}
                />
                <span style={{ color: "red" }}>{errors?.title?.message}</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p>Text of Notes page (max 60 words):*</p>
              <div className="w-75 ms-5">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control w-100"
                  defaultValue="The introduction states the aim of this course, what you will learn, and how you can apply your learning immediately in your work."
                  {...register("description", {
                    required: {
                      value: true,
                      message: "Description is required",
                    },
                  })}
                />
                <span style={{ color: "red" }}>
                  {errors?.description?.message}
                </span>
              </div>
            </div>

            <div className="form-outline w-75 ms-auto edit_course_btns mt-4">
              <button
                type="submit"
                disabled={!isDirty && !isValid}
                className="green-btn ms-5"
              >
                Save
              </button>
              <button type="button" className="green-btn green-btn2">
                View Course So Far
              </button>
              <button
                type="button"
                //onClick={handleDelete}
                className="green-btn delete-btn"
              >
                Publish
              </button>
              <button type="button" className="green-btn back-btn">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
