import { createSlice } from "@reduxjs/toolkit";

const soundSlice = createSlice({
  name: "sound",
  initialState: {
    sound: false,
  },
  reducers: {
    soundOn: (state) => {
      state.sound = true;
    },
    soundOf: (state) => {
      state.sound = false;
    },
  },
});
export const { soundOn, soundOf } = soundSlice.actions;
export default soundSlice.reducer;
