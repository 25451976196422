import React from "react";

const MaintenancePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8f8f8",
      }}
    >
      <h1
        style={{
          fontSize: "48px",
          marginBottom: "24px",
        }}
      >
        Maintenance in Progress
      </h1>
      <p
        style={{
          fontSize: "24px",
          textAlign: "center",
          maxWidth: "600px",
        }}
      >
        We are currently performing maintenance on our website. Please check
        back soon.
      </p>
    </div>
  );
};

export default MaintenancePage;
