import React from "react";
import Wave from "../assets/audio/click.wav";

const AppButton = ({ type, className, onClick, children, ...buttonProps }) => {
  const audio = new Audio(Wave);

  const playSound = () => {
    const isPlay = localStorage.getItem("soundPropCourse");
    if (isPlay === "true") {
      audio.play();
    }
  };
  return (
    <button
      type={type}
      className={className}
      onClick={() => {
        playSound();
        onClick();
      }}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default AppButton;
