export const home = "buildcourse/home";
export const Login = "buildcourse/sign-in";
export const socialLogin = "buildcourse/social-login";
export const SignUp = "buildcourse/sign-up";
export const categories = "buildcourse/categories";
export const topic = "buildcourse/category";
export const authorCourse = "buildcourse/author-courses";
export const videoExists = "buildcourse/video-exist";
export const producerData = "buildcourse/producer-courses";
export const topCourses = "buildcourse/today-top-courses";
export const searchCourses = "buildcourse/search";
export const videoReport = "buildcourse/missing-report";
export const getTopicData = "buildcourse/topic";
export const myCourse = "course/my-courses";
export const getTopic = "course/category";
export const videoCount = "buildcourse/view-count";
export const createCourse = "course/save";
export const template = "course/import";
export const courseDetails = "course/details";
export const updateCourse = "course/update";
export const categoryList = "course/categories";
export const getGalleryList = "course/gallery";
export const deleteCourse = "course/delete";
export const imageAndText = "course/save-image-text";
export const deleteImageAndText = "course/delete-image-text";
export const saveVideo = "course/save-video";
export const deleteVideo = "course/delete-video";
export const saveEmbeds = "course/save-embeds";
export const viewCourse = "course/Course";
export const saveIntroduction = "course/save-introduction";
export const deleteIntroduction = "course/delete-questionnaire";
export const saveKeyPoints = "course/save-key-point";
export const saveNotesPage = "course/save-notes";
export const saveQuiz = "course/save-quiz";
